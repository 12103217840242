// Colors
/* TODO: Color scheme should perhaps be a double layered system? */
/* dict with colors, and then can create themes on top of this dict. */

export enum colorScheme {
  GRAY = "#B1B1B1",
  GRAY_DARK = "#919191",
  NAVY = "#04485f",
  NAVY_LIGHT = "#14586f",
  RED_WEAK = "#c78a8b",
  TURQUOISE = "#428095",
  TURQUOISE_LIGHT = "#72ADC1",
  GREEN = "#0b8128"
}

export { colorScheme as cs };
