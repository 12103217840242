import { Box, Center, SimpleGrid, Text } from "@chakra-ui/react";
import { TSound } from "@imaldev/imal-factory/abc";
import { GenericDrawer } from "../../shared/components/GenericDrawer";

export const DrawerSound = (props: {
  activeSound?: TSound;
  isOpen: boolean;
  onClose?: () => void;
  onSetSound?: (s: TSound) => void;
  sounds: string[];
}) => {
  return (
    <GenericDrawer isOpen={props.isOpen} onClose={props.onClose}>
      <SimpleGrid columns={8} w="100%" h="30em" gap=".65em">
        {props.sounds.map((s, i) => (
          <Center bg={props.activeSound === s ? "yellow.400" : "gray.300"} w="100%" h="4em" key={i}>
            <Text>{s}</Text>
          </Center>
        ))}
      </SimpleGrid>
    </GenericDrawer>
  );
};

export const DrawerFont = (props: { isOpen: boolean; onClose?: () => void; sounds: string[] }) => {
  return (
    <GenericDrawer isOpen={props.isOpen} onClose={props.onClose}>
      <SimpleGrid columns={8}>
        {props.sounds.map((s, i) => (
          <Box key={i}>
            <Text>{s}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </GenericDrawer>
  );
};

export const DrawerGuidelines = (props: {
  isOpen: boolean;
  onClose?: () => void;
  sounds: string[];
}) => {
  return (
    <GenericDrawer isOpen={props.isOpen} onClose={props.onClose}>
      <SimpleGrid columns={8}>
        {props.sounds.map((s, i) => (
          <Box key={i}>
            <Text>{s}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </GenericDrawer>
  );
};
