import { Center, SimpleGrid, Text, useDimensions } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useLongPress } from "../util";

/* Support selecting both single- and multiple sounds. Does this make sense though? */
export const sounds = "abcdefghijklmnopqrstuvwxyz".split("");

/* Possibly do some animation when click on a sound? Some bouncing thing? idk... experiment when essential features are done. */

/* TODO: try to render sounds such that no scrolling is required on any phone/tablet device.  */

export const SoundSelector = (props: {}) => {
  /* Change this to zustand. */
  const [selectedSound, setSelectedSound] = useState<string | null>(null);

  return (
    <SimpleGrid columns={5} gap="1em" boxSizing="border-box" mt="1em" w="100%" bg="yellow.50">
      {sounds.map((s, i) => (
        <Sound
          key={i}
          onClick={() => setSelectedSound(s)}
          value={s}
          variant={s === selectedSound ? "focused" : "default"}
        />
      ))}
    </SimpleGrid>
  );
};

export const Sound = (props: {
  onClick: () => void;
  onLongClick?: () => void;
  value: string;
  variant?: "focused" | "selected" | "default";
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const dimensions = useDimensions(ref);

  const lp = useLongPress(props.onLongClick ?? (() => console.log("long!")), props.onClick, {
    shouldPreventDefault: true,
    delay: 500
  });

  return (
    <Center
      bg={
        props.variant === "focused"
          ? "orange.400"
          : props.variant === "selected"
          ? "blue.400"
          : "blue.200"
      }
      borderRadius="xl"
      boxShadow="lg"
      h={dimensions?.contentBox.width}
      p=".2em"
      ref={ref}
      {...lp}
    >
      <Text as="b" color={props.variant === "default" ? "gray.200" : "gray.50"} fontSize="2xl">
        {props.value}
      </Text>
    </Center>
  );
};
