import { Box, Flex } from "@chakra-ui/react";
import { getSounds } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { range } from "ramda";
import { useGlobalTxt } from "../../../../../../i18n/useGlobalTxt";
import { germanGrundschrift } from "../../../../../../utils/utilsiMAL/fonts/germanGrundschrift/germanGrundschrift";
import { getDefaultFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { imalGerman } from "../../../../../../utils/utilsiMAL/soundSequences/locales/de_DE/de_DE";
import { useBoundingRect } from "../../../../../../utils/utilsReact/useBoundingRect";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextUserDataAppSheetsAbc";
import { useSheets } from "../../contexts/ContextSheets";
import { BlankSheet } from "../shared/components/sheets/BlankSheet/BlankSheet";
import {
  ESheet,
  getDefaultArgs,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { TWithFont } from "../shared/sheetTypes/withFont";
import { TWithSounds } from "../shared/sheetTypes/withSounds";

/* TODO: change link at bottom to a route with info and instructions about the mapping. */
/* TODO: figure out title for the mapping sheet. */

/* TODO?: check if can have different names for routes depending on locale. */
/* TODO: fix show all sounds in some sheets. Sheets where sound set not used. */

/* Would it better to have a type for this case called, TWithTypeface? */
/* Or is it not necessary? Can do the same thing with TWithFont? */

export type TReadAndWriteMapping = TNewSheetType<ESheet.READ_AND_WRITE_MAPPING> &
  TWithSounds &
  TWithFont & {
    /* Feels weird, but sheet is likely to always simple simple enough that it won't matter. */
    variant: "student" | "teacher";
  };

export const Sheet: SheetPreview<ESheet.READ_AND_WRITE_MAPPING> = ({ sheetProps: sheet }) => {
  return sheet.variant === "student" ? (
    <SheetStudent sounds={sheet.sounds} />
  ) : (
    <SheetTeacher sounds={sheet.sounds} />
  );
};

const Checkbox = ({ x = 10, y = 10 }: { x?: number; y?: number }) => {
  return (
    <rect
      height=".25rem"
      fill="#e7e7e7"
      rx=".05rem"
      stroke="black"
      strokeWidth=".018rem"
      width=".25rem"
      x={`${x}%`}
      y={`${y}%`}
    />
  );
};

const SectionTitle = ({ bg = "#4D728F", title = "", x = 0, y = 0 }) => {
  return (
    <svg x={`${x}%`} y={`${y}%`}>
      <rect height="4%" fill={bg} rx=".15rem" width="85%" x="3%" />
      <text fontFamily="arial" fill="white" fontSize="2.5" fontWeight="bold" x="7.5%" y="2.7%">
        {title}
      </text>
    </svg>
  );
};

/* huh... default args infers type of props... */
/* all props are optional, and don't need type definition. */
/* Would this be useful for other places, too? */
/* Take an optional prop second line? */
/* or what is text can be string or [string, string]. */
const ColumnLabel = ({ text = "" as string | [string, string], x = 0, y = 0 }) => {
  return typeof text === "string" ? (
    <text
      fontWeight="bold"
      fontFamily="arial"
      fontSize=".165rem"
      textAnchor="middle"
      x={`${x}%`}
      y={`${y}%`}
    >
      {text}
    </text>
  ) : (
    <>
      <text
        fontFamily="arial"
        fontSize=".15rem"
        fontWeight="bold"
        textAnchor="middle"
        x={`${x}%`}
        y={`${y - 0.7}%`}
      >
        {text[0]}
      </text>
      <text
        fontFamily="arial"
        fontSize=".15rem"
        fontWeight="bold"
        textAnchor="middle"
        x={`${x}%`}
        y={`${y + 0.9}%`}
      >
        {text[1]}
      </text>
    </>
  );
};

const Evaluation = ({ text = "", x = 0, y = 0 }) => {
  return (
    <svg x={`${x}%`} y={`${y}%`}>
      <text fontFamily="arial" fontSize="2.5" fontWeight="bold" x="1" y="17">
        {text}
      </text>
      <Checkbox x={50} />
      <Checkbox x={66.5} />
      <Checkbox x={85} />
    </svg>
  );
};

const SmallText = ({
  fill = "#777",
  fontSize = "1.75",
  text,
  x = 0,
  y = 2
}: {
  fill?: string;
  fontSize?: string;
  text: string;
  x?: number;
  y?: number;
}) => {
  return (
    <text fill={fill} fontFamily="arial" fontSize={fontSize} x={x} y={y}>
      {text}
    </text>
  );
};

const NameAndDate = ({ withDate = false as boolean, y = 5 }) => {
  const { txt_g } = useGlobalTxt();

  return (
    <svg y={y}>
      <text fill="#444" fontFamily="arial" fontSize={3.5} fontWeight="bold" x={3.2} y={3}>
        {txt_g.name}:
      </text>
      {withDate && (
        <text fill="#444" fontFamily="arial" fontSize={3.5} fontWeight="bold" x={42} y={3}>
          {txt_g.date}:
        </text>
      )}
    </svg>
  );
};

const TitleAndLink = () => {
  return (
    <svg x={67.4} y={1.3}>
      <text fill="#999" fontFamily="arial" fontSize=".15rem" fontWeight="bold" y={2}>
        iMAL-Kartierung
      </text>
      <SmallText text={window.location.hostname} y={4.45} />
    </svg>
  );
};

const N_ROWS = 6,
  SOUNDS_PER_ROW = 8,
  STROKE_W = ".014rem";

const translationsStudent = {
  [ELocale.de_DE]: {
    fold: "Falten",
    nb_teacher_and_student_say_letter_sound: () =>
      `NB: Pädgog:in und Schüler:in sagen den Buchstabenlaut: F = "ffff", nicht ${"ef"!}`,
    student_sheet: "Schülerblatt",
    remembering_sounds_for_writing: "Abrufen der Buchstaben zum Schreiben",
    remembering_sounds_for_reading: "Abrufen der Buchstaben zum Lesen",
    fold_sheet_teacher_reads:
      "Falte das Blatt. Pädgog:in liest Buchstabenlaut vor. Schüler:in schreibt auf Linie.",
    student_reads_teacher_records_performance:
      "Schüler:in liest; Pädgog:in markiert auf Lehrerblatt."
  },
  [ELocale.es_ES]: {
    fold: "Doblar",
    nb_teacher_and_student_say_letter_sound: () =>
      `Educadores y alumnos dicen el Sonido de las letra F = "ffff", y no ${"efe"}`,
    student_sheet: "Ficha de estudiante",
    remembering_sounds_for_writing: "Recordar letras para escribir",
    remembering_sounds_for_reading: "Recordar letras para leer",
    student_reads_teacher_records_performance:
      "El alumno lee; el profesor marca en la hoja del profesor"
  }
};

export const SheetStudent = ({ sounds = [] as string[] }) => {
  /* Use default typeface. Actually, should be able to select typeface here. */
  /* Just use grundschrift regular for now. */
  const { txt } = useTxt(translationsStudent);

  /* Shared for write and read boxes. */
  const AREA_W = 90,
    SOUND_SEP = AREA_W / 10,
    ROW_SEP = 8,
    X_OFFSET = 5.5;

  const write = {
    H: 52,
    W: 90,
    LINE_W: 8,
    HSEP: 3,
    VSEP: 10,
    HEIGHT: 58,
    X_OFFSET: 5.5,
    Y_OFFSET: 17.5
  };

  /* TODO: finish refactor to use these kinds of styling values. */

  /* Can define this closer to rect/where it's used? */
  const read = {
    H: 58,
    W: 90,
    LINE_W: 8,
    HSEP: 3,
    VSEP: 10,
    HEIGHT: 58,
    X_OFFSET: 5.5,
    Y_OFFSET: 5.5,
    SOUND_SEP: 13
  };

  return (
    <BlankSheet logoSize="small">
      <text fill="#999" fontFamily="arial" fontSize=".15rem" fontWeight="bold" x={3} y="2.5%">
        {txt.student_sheet}
      </text>
      <TitleAndLink />
      <NameAndDate withDate y={5.5} />
      <svg y="7%">
        <SmallText text={txt.nb_teacher_and_student_say_letter_sound()} x={17.65} y={3} />
        <svg y={4.8}>
          <SectionTitle bg="#4C956E" title={txt.remembering_sounds_for_writing} />
          <SmallText text={txt.student_sheet} x={7.2} y={8.1} />
        </svg>
        <svg y="2.7%">
          <rect
            width={write.W}
            height={write.H}
            fill="#c8e2d4"
            rx=".2rem"
            stroke="black"
            strokeWidth=".02rem"
            x="3.5%"
            y="7.5%"
          />
          {range(0, N_ROWS).map((row) =>
            range(0, SOUNDS_PER_ROW).map(
              (col, i) =>
                sounds[row * SOUNDS_PER_ROW + col] && (
                  <line
                    key={i}
                    stroke="#444"
                    strokeWidth=".02rem"
                    x1={X_OFFSET + write.HSEP * col + write.LINE_W * col}
                    x2={X_OFFSET + write.HSEP * col + write.LINE_W * col + write.LINE_W}
                    y1={write.Y_OFFSET + ROW_SEP * row}
                    y2={write.Y_OFFSET + ROW_SEP * row}
                  />
                )
            )
          )}
        </svg>
        <svg y="48.9%">
          <SmallText text={`(${txt.fold})`} x={87.7} y={3} />
          <line x1="1%" x2="100%" stroke="#888" strokeWidth=".02rem" strokeDasharray=".4 .4 .4" />
        </svg>
        <svg y="47.2%">
          <svg y={2.5}>
            <SectionTitle title={txt.remembering_sounds_for_reading} y={2} />
            <SmallText text={txt.student_reads_teacher_records_performance} x={6.5} y={10.95} />
          </svg>
          <svg y="10.5%">
            <rect
              width="95%"
              height="34%"
              fill="#cbd7df"
              rx=".2rem"
              stroke="black"
              strokeWidth={STROKE_W}
              x="3%"
            />
            {sounds.map((s, i) => (
              <text
                textAnchor="middle"
                fontSize=".35rem"
                fontFamily={germanGrundschrift.fonts.regular!.fontFamily}
                key={i}
                x={X_OFFSET + 4 + (SOUND_SEP + 2) * (i % 8)}
                y={5.3 + 7.7 * Math.floor(i / 8)}
              >
                {s}
              </text>
            ))}
          </svg>
        </svg>
      </svg>
    </BlankSheet>
  );
};

const SoundBox = ({ sound = "", h = 0, w = 0, x = 0, y = 0 }) => {
  return (
    <>
      <polygon fill="#e7f1eb" points={`${x},${y} ${x},${y + h} ${x + w},${y}`} />
      <polygon fill="#dce7ef" points={`${x + w},${y} ${x + w},${y + h} ${x},${y + h}`} />
      <line
        x1={x + w}
        x2={x}
        y1={y}
        y2={y + h}
        stroke="#c5c5c5"
        strokeWidth=".0135rem"
        strokeDasharray=".3 .3 .3"
      />
      {/* TODO: position text based on "fontLevels" in the sound. */}
      <text
        fontFamily={germanGrundschrift.fonts.regular!.fontFamily}
        fontSize={7}
        textAnchor="middle"
        x={x + 5.5}
        y={y + 5.7}
      >
        {sound}
      </text>
    </>
  );
};

const translationsTeacher = {
  [ELocale.de_DE]: {
    nb_teacher_and_student_say_letter_sound: "",
    teacher_sheet: "Lehrerblatt",
    read_and_write_with_known_sounds: "Lese- und Schreibfertigkeiten mit bekannten Lauten",
    color_boxes_after_students_performance:
      "Kästchen farblich markieren wenn Buchstabenlaut richtig erkannt wurde.",
    read_syllables_vowel_first: "Lesen von Silben (Vokal zuerst)",
    read_syllables_vowel_last: "Lesen von Silben (Vokal zuletzt)",
    read_non_words: "Lesen von Pseudowörtern (3 Laute)",
    mastering: "Meistert",
    reads: "Liest",
    spells: ["Buchstaben-", "sammler"] as [string, string],
    not_reading: "Liest nicht",
    skeleton_writing: ["Skelett-", "schreiben"] as [string, string],
    not_mastering: "Meistert nicht",
    write_with_known_sounds: "Schreiben mit bekannten Lauten"
  },
  [ELocale.es_ES]: {
    nb_teacher_and_student_say_letter_sound: "",
    teacher_sheet: "Ficha de profesor",
    read_and_write_with_known_sounds: "Lectura y escritura con sonidos conocidos",
    color_boxes_after_students_performance:
      "Marca la casilla en color si el sonido de la letra ha sido reconocido correctamente",
    read_syllables_vowel_first: "Lectura de sílabas (primera vocal)",
    read_syllables_vowel_last: "Lectura de sílabas (última vocal)",
    read_non_words: "Lectura de Pseudónimos (3 sonidos)",
    mastering: "Dominado",
    reads: "Lee",
    spells: ["foo", "bar"] as [string, string],
    not_reading: "No lee",

    skeleton_writing: ["Comerse", "letras"] as [string, string],
    not_mastering: "No dominado",
    write_with_known_sounds: "Escribir con sonidos /conocidos"
  }
};

/* sounds should be passed as prop? */
export const SheetTeacher = ({ sounds = [] as string[] }) => {
  const { txt_g } = useGlobalTxt();
  const { txt } = useTxt(translationsTeacher);
  const { defaultSequence } = useDefaultSequence();
  /* contants for the grid? to make code more readable/avoid magic numbers. */
  const GRID_W = 90,
    GRID_H = 53,
    BOX_W = GRID_W / SOUNDS_PER_ROW - 0.08; // TODO: not 100% correct, but close enough?

  return (
    <BlankSheet logoSize="small">
      <text fill="#999" fontFamily="arial" fontSize=".15rem" fontWeight="bold" x={3} y="2.5%">
        {txt.teacher_sheet}
      </text>
      {/* Potentially make component out of this. */}
      <TitleAndLink />
      <NameAndDate y={5.5} />
      <svg x="3.5" y={11.3}>
        <SmallText text={txt.color_boxes_after_students_performance} />
        <svg y={3.8}>
          <rect width={GRID_W} height={GRID_H} fill="#eee" stroke="black" strokeWidth=".025rem" />

          {/* would be nice to have some layout of columns and rows in all 3 places with sounds. */}
          {range(0, N_ROWS).map((r) =>
            range(0, SOUNDS_PER_ROW).map(
              (c, i) =>
                sounds[r * SOUNDS_PER_ROW + c] && (
                  <svg key={i}>
                    <rect
                      fill="white"
                      height={GRID_H / N_ROWS}
                      stroke="#444"
                      strokeWidth={STROKE_W}
                      width={BOX_W}
                      x={0.4 + BOX_W * c}
                      y={0.4 + r * (Math.floor(GRID_H / N_ROWS) + 0.7)}
                    />
                    <SoundBox
                      h={0.7 + Math.floor(GRID_H / N_ROWS)}
                      sound={defaultSequence.sounds[r * SOUNDS_PER_ROW + c]}
                      w={BOX_W}
                      x={0.4 + BOX_W * c}
                      y={0.4 + r * (Math.floor(GRID_H / N_ROWS) + 0.7)}
                    />
                  </svg>
                )
            )
          )}
        </svg>
      </svg>

      <svg y="51.4%">
        <rect
          fill="#eee"
          height="12%"
          rx=".2rem"
          stroke="#222"
          strokeWidth=".02rem"
          width="95%"
          x="3%"
          y=".5"
        />
        <text fontSize={2.6} fontWeight="bold" x={6.5} y={7}>
          {txt_g.date}:
        </text>
        {range(0, 8).map((n) => {
          const LINE_W = 15,
            LINE_SEP = 3,
            LINE_X_OFFSET = 20;

          return (
            <line
              key={n}
              x1={LINE_X_OFFSET + (LINE_W + LINE_SEP) * (n % 4)}
              x2={LINE_X_OFFSET + (LINE_W + LINE_SEP) * (n % 4) + LINE_W}
              y1={n < 4 ? 7 : 14.5}
              y2={n < 4 ? 7 : 14.5}
              stroke="#333"
              strokeWidth=".2"
            />
          );
        })}
      </svg>
      <svg x="2%" y="66%">
        <SectionTitle title={txt.read_and_write_with_known_sounds} y={1} />
        <ColumnLabel text={txt.reads} x={51.8} y={7.9} />
        <ColumnLabel text={txt.spells} x={68} y={7.9} />
        <ColumnLabel text={txt.not_reading} x={87} y={7.9} />
        <Evaluation text={txt.read_syllables_vowel_first} />
        <Evaluation text={txt.read_syllables_vowel_last} y={4} />
        <Evaluation text={txt.read_non_words} y={8} />
      </svg>
      <svg x="2%" y="83%">
        <ColumnLabel text={txt.mastering} x={51.8} y={7.9} />
        <ColumnLabel text={txt.skeleton_writing} x={67.8} y={7.9} />
        <ColumnLabel text={txt.not_mastering} x={87.2} y={7.9} />
        <Evaluation text={txt.write_with_known_sounds} />
      </svg>
    </BlankSheet>
  );
};

export const fnMkSheet: MkSheet<TReadAndWriteMapping> = (arg) => {
  const { locale, pSheet } = getDefaultArgs(arg);
  const s: TReadAndWriteMapping = {
    ...mkAbstractSheet(),
    font: getDefaultFont(locale),
    name: ESheet.READ_AND_WRITE_MAPPING,
    variant: "student",
    sounds: [],
    ...pSheet
  };
  return s;
};

/* TODO: Either, have a tab-like thingy where can switch between the different */
/* sheet for this module, or show sheets side-by-side. */
/* Regine said something about having 3 sheets? A third one to see if student can */
/* actually read(/write) with the mapped sounds. */
/* Did she mean a third sheet for this module, or creating some of the existing sheets? */

/* This editor needs a special design for phone... */

/* Need to get sounds of active seq when creating sheet..? */

/* 1. Disable this sheet for now, but figure out a way to add it again, later. */
/* Hopefully right after finishing advertisement trip. */

/*  Though the gist of it may be similar to that of the old solution? */
/* So, initially after editing, only incudeo . */

/* 2. The ideal solution would be to have a kind of task sheet which has multiple sheets. */
/* Then, when creating the pdf, check the type of the image property: */
/* - if object, use images in this object. */
/* - if array (of the same object above), make a page for each page-image-object in this array. */
/*   This could also be used to show two stacked sheets in the booklet sheets overview. */

export const EditorMapProficiency = () => {
  const { activeSheet: sheet } = useSheets() as { activeSheet: TReadAndWriteMapping };
  const [refBrStudent, brS] = useBoundingRect();
  const [refBrTeacher, brT] = useBoundingRect();

  /* TODO: Pass down sheet props to sheet. */

  return (
    <Flex flex={1} h="100%" maxW="70em" mx="auto" w="100%" p="1em" boxSizing="border-box">
      <Flex flex={1} h="100%" maxW="90em" mx="auto" gridGap="1rem" ref={refBrStudent}>
        <Flex h="100%" w={brS.height / 1.4} pos="relative">
          <SheetStudent />
        </Flex>
      </Flex>
      <Flex flex={1} h="100%" maxW="90em" mx="auto" gridGap="1rem" ref={refBrTeacher}>
        <SheetTeacher />
      </Flex>
    </Flex>
  );
};

/* Will we have something like a default sequence for each loc */
export const configReadAndWriteMapping: TSheetConfig<ESheet.READ_AND_WRITE_MAPPING> = {
  Editor: EditorMapProficiency,
  fnMkSheet,
  menuSheets: {
    [ELocale.de_DE]: fnMkSheet({ pSheet: { sounds: imalGerman.sounds } }),
    [ELocale.es_ES]: fnMkSheet({
      pSheet: { sounds: getSounds(ELocale.es_ES) }
    })
  },
  Sheet,
  videos: {}
};
