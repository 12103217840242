import { configEncircleWordsWithSound, TEncircleWordsWithSound } from "./EncircleWordsWithSound";
import { configLetterLargeAndSmall, TLetterLargeAndSmall } from "./LetterLargeAndSmall";
import { configOneLargeSound, TOneLargeSound } from "./OneLargeSound";
import { configOneLargeWWII, TOneLargeWWII } from "./OneLargeWWII";
import { configReadAndWriteMapping, TReadAndWriteMapping } from "./ReadAndWriteMapping";
import { ESheet, TSheetConfig } from "./shared/misc";
import { configSoundPoster, TSoundPoster } from "./SoundPoster";
import { configTrackAndBox, TTrackAndBox } from "./TrackAndBox";
import { configTrackAndWWII4x, TTrackAndWWII4x } from "./TrackAndWWII4x";
import { configWriteSoundRows, TWriteSoundRows } from "./WriteSoundRows";
import { configWSR2xAndRead3x, TWrite2xAndRead3x } from "./WSR2xAndRead3x";
import { configWSRx2AndWWIIx4, TWSR2xAndWWII4x } from "./WSR2xAndWWII4x";
import { configWWII8x, TWWII8x } from "./WWII8x";

/* TODO: missing three sheets after refactor: (re-add later in this order) */
/* - TrackAndWSR2xAndFindAndWWIIx2 (rename to TrackSoundAndWSR2xAndSoundGridAndWWII2x) */
/* - RearrangeWordLetters (letters or sounds?) */
/* - LineFromWordToImageWriteWord (rename to LineFromWordToImageAndWriteWord) */

export type TTaskSheet =
  | TEncircleWordsWithSound
  | TOneLargeSound
  | TTrackAndBox
  | TTrackAndWWII4x
  | TLetterLargeAndSmall
  | TReadAndWriteMapping
  | TOneLargeWWII
  | TSoundPoster
  | TWriteSoundRows
  | TWrite2xAndRead3x
  | TWSR2xAndWWII4x
  | TWWII8x;

export const sheetConfigs: { [ST in ESheet]: TSheetConfig<ST> } = {
  [ESheet.ENCIRCLE_WORDS_WITH_SOUND]: configEncircleWordsWithSound,
  [ESheet.LETTER_LARGE_AND_SMALL]: configLetterLargeAndSmall,
  [ESheet.READ_AND_WRITE_MAPPING]: configReadAndWriteMapping,
  [ESheet.ONE_LARGE_SOUND]: configOneLargeSound,
  [ESheet.ONE_LARGE_WWII]: configOneLargeWWII,
  [ESheet.SOUND_POSTER]: configSoundPoster,
  [ESheet.TRACK_AND_BOX]: configTrackAndBox,
  [ESheet.TRACK_AND_WWII_4X]: configTrackAndWWII4x,
  [ESheet.WRITE_SOUND_ROWS]: configWriteSoundRows,
  [ESheet.WSR_2x_AND_READ_3X]: configWSR2xAndRead3x,
  [ESheet.WSR_2X_AND_WWII_4X]: configWSRx2AndWWIIx4,
  [ESheet.WWII_8X]: configWWII8x
};

export const TaskSheet = (props: { interactive?: boolean; sheetProps: TTaskSheet }) => {
  // No idea how to do this without `any`...
  const Component: any = sheetConfigs[props.sheetProps.name].Sheet;
  return <Component {...props} />;
};
