import { Text } from "@chakra-ui/react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BookletCreator } from "./routes/booklet-creator";
import { Home } from "./routes/home";
/* import { Home } from "./routes/home"; */
import { PurchaseSubscription, Profile } from "./routes/settings";
import { SheetEditor } from "./routes/sheet-editor";
import { MobileSignUp } from "./routes/sign-up";

/* TODO: horizontal mobile mode, too? Probably just... disable landscape mode for now. */

/* Create separate files/dirs for each route? */
/* Will also make it easier if at some point decide to migrate to nextjs. */

/* Some router should be protected. Ie. if try to navigate to them without valid state, */
/* user is redirected to `/home`. */
const MobileRoot = () => {
  return (
    <Routes>
      <Route path="home" element={<Home />} />
      <Route path="settings/*">
        <Route index element={<Profile />} />
        <Route path="purchase-subscription" element={<PurchaseSubscription />} />
      </Route>
      <Route path="booklet-editor" element={<BookletCreator />} />
      <Route path="editor" element={<SheetEditor />} />
      <Route path="payment-success" element={<Text>payment succeeded</Text>} />
      <Route path="payment-failed" element={<Text>payment failed</Text>} />
      <Route path="sign-up" element={<MobileSignUp />} />
      <Route path="*" element={<Navigate to="home" />} />
    </Routes>
  );
};

/* TODO: a page for previewing an entire booklet. Not the same as previewing the booklet which is being edited. */

export { MobileRoot as AppSheetsAbcMobile };
