import { SimpleGrid } from "@chakra-ui/react";
import { append, compose, equals, not, takeWhile, without } from "ramda";
import { useState } from "react";
import { Sound } from "./SoundSelector";

export const SoundsSelector = (props: { sounds?: string[] }) => {
  /* Migrate to zustand. */
  const [selectedSounds, setSelectedSounds] = useState<string[]>([]);
  const [focusedSound, setFocusedSound] = useState<string>("");

  return (
    <SimpleGrid columns={5} gap="1em" boxSizing="border-box" mt="1em" w="100%" bg="yellow.50">
      {(props.sounds ?? "abc".split("")).map((s, i) => (
        <Sound
          key={i}
          onClick={() =>
            focusedSound !== s &&
            setSelectedSounds((sounds) =>
              sounds.includes(s) ? without([s], sounds) : append(s, sounds)
            )
          }
          onLongClick={() => {
            setFocusedSound(s);
            setSelectedSounds(takeWhile(compose(not, equals(s)), props.sounds ?? "abc".split("")));
          }}
          value={s}
          variant={
            s === focusedSound ? "focused" : selectedSounds.includes(s) ? "selected" : "default"
          }
        />
      ))}
    </SimpleGrid>
  );
};
