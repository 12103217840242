import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { WithChildren } from "../../../../../../utils/utilsReact/types";

/* Look into the library dnd-kit. */
/* Looks like should be possible to create a drawer with can be open and closed */
/* by dragging the titlebar. */

/* Would be cool to have the kind of drawer which can be closed by dragging downwards. */
/* react-drag-drawer: https://www.npmjs.com/package/react-drag-drawer */
/* Nah... create own component with framer-motion. */

/* Should be able to take heigth as prop. WithHeight? */
export const GenericDrawer = (
  props: WithChildren & {
    h?: string; // TODO: use more precise type
    isOpen?: boolean;
    noHeader?: boolean;
    onClose?: () => void;
    title?: string | ReactElement;
  }
) => {
  return (
    <Drawer isOpen={props.isOpen ?? true} onClose={props.onClose ?? (() => {})} placement="bottom">
      <DrawerOverlay />
      <DrawerContent borderTopRadius="20px">
        {props.noHeader ? (
          <></>
        ) : (
          <DrawerHeader bg="blue.100" borderTopRadius="20px">
            <DrawerCloseButton />
            {props.title === undefined ? (
              <Heading />
            ) : typeof props.title === "string" ? (
              <Heading>{props.title}</Heading>
            ) : (
              props.title
            )}
          </DrawerHeader>
        )}
        <DrawerBody bg="gray.100">
          <Flex h={props.h ?? "62vh"} w="100%" pb="1em" boxSizing="border-box">
            {props.children}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
