import { usePrevious } from "@chakra-ui/react";
import { getSounds } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useWDocumentData, wSetDoc, wUpdateDoc } from "../../../../../api/shared";
import { useUser } from "../../../../../api/user/context";
import { IS_FAST_DEV } from "../../../../../constants";
import {
  getBookSequence,
  SoundSequence
} from "../../../../../utils/utilsiMAL/soundSequences/sequences";
import { WithChildren } from "../../../../../utils/utilsReact/types";
import { PLACEHOLDER } from "../../../../../utils/utilsTS/misc";
import { UUID } from "../../../../../utils/utilsTS/uuid/uuid";

type TSheetsAbcUserData = {
  idDefaultSequence: string;
  idDefaultTypeface: string;
  subscription: {
    /* Just make some subscription stuff that works, try it out, and THEN think about */
    /* how to handle changing subscriptions (price, name, etc.) */
    /* Also, think about trial access at that point, too. */
    periodEnds: Date | null;
    isActive: boolean;
    planId: string | null; // Stripe.Price["id"]
  };
  metadata: {
    dateLastUsage: Date;
  };
};

const placeholderData: TSheetsAbcUserData = {
  idDefaultSequence: IS_FAST_DEV ? "FAST_DEV" : PLACEHOLDER,
  idDefaultTypeface: IS_FAST_DEV ? "FAST_DEV" : PLACEHOLDER,
  subscription: {
    isActive: false,
    periodEnds: new Date(),
    planId: "foobar"
  },
  metadata: {
    dateLastUsage: new Date()
  }
};

export const ContextSheetsAbcUserData = createContext<{
  userData: TSheetsAbcUserData;
  isLoading: boolean;
}>(null!);

/* TODO: Should always have retrieved user when rendering this provider? */
/* If that's true, won't need checks on `haveUser`. */
export const ProviderUserDataAppSheetsAbc = ({ children }: WithChildren) => {
  const { locale } = useLocale();
  const { isLoading: isLoadingUser, user } = useUser();
  const docPath = useMemo(() => `/app_sheets_abc/${locale}/users/${user.id}/`, [locale, user.id]);
  /* What happens when data doesn't match the type/missing fields?? */
  /* Can write code so don't matter? */
  const [userData, isLoadingData] = useWDocumentData<TSheetsAbcUserData>(
    user.id === "" ? "/foo/bar" : docPath
  );

  useEffect(() => {
    /* Create doc on initial login. */
    if (isLoadingUser && !isLoadingData && !userData) {
      wSetDoc(docPath, placeholderData);
    }
  }, [userData, isLoadingData, isLoadingUser, user, locale]);

  return (
    <ContextSheetsAbcUserData.Provider
      value={{
        userData: userData ?? placeholderData,
        isLoading: isLoadingUser && !isLoadingData
      }}
    >
      {children}
    </ContextSheetsAbcUserData.Provider>
  );
};

/* Put some things in config? Ie. two nested objects: */
/* config and access. and then trial obj inside access? */
export const useUserDataAppSheetsAbc = () => useContext(ContextSheetsAbcUserData);

const phSeq: SoundSequence = {
  bookPicture: "lmao",
  displayName: "Placeholder",
  id: "placeholder",
  name: "foobar",
  sounds: getSounds(ELocale.de_DE)
};

type TContextDefaultSequence = {
  defaultSequence: SoundSequence;
  hasDefaultSequence: boolean;
  isLoadingSequence: boolean;
  setDefaultSequence: (sequenceId: string) => void;
};

export const ContextDefaultSequence = createContext<TContextDefaultSequence>(null!);

/* This can be merged with the other context? */
/* All _app data_ for a user for an app is together. */

export const ProviderDefaultSequence = ({ children = undefined as JSX.Element | undefined }) => {
  const { userData, isLoading: isLoadingUserAppData } = useUserDataAppSheetsAbc();
  const { locale } = useLocale();
  const { user, isLoading } = useUser();
  const [dbSeq] = IS_FAST_DEV
    ? [phSeq]
    : useWDocumentData<SoundSequence>(
        `app_sheets_abc/${locale}/users/${user.id === "" ? "foobar" : user.id}/sequences/${
          userData.idDefaultSequence ?? "foobarId"
        }`
      );

  const defaultSeq = useMemo(
    () => (IS_FAST_DEV ? phSeq : getBookSequence(locale, userData.idDefaultSequence) ?? dbSeq),
    [dbSeq?.id, userData.idDefaultSequence, locale]
  );

  const prevDefault = usePrevious(defaultSeq);

  const setDefaultSequence = useCallback(
    (seqId: UUID) => {
      wUpdateDoc(`/app_sheets_abc/${locale}/users/${user.id}`, {
        idDefaultSequence: seqId
      });
    },
    [locale, user.id]
  );

  return (
    <ContextDefaultSequence.Provider
      value={
        IS_FAST_DEV
          ? {
              defaultSequence: phSeq,
              hasDefaultSequence: true,
              isLoadingSequence: false,
              setDefaultSequence: () => {}
            }
          : {
              defaultSequence: defaultSeq ?? prevDefault ?? phSeq,
              hasDefaultSequence: userData.idDefaultSequence !== "placeholder",
              isLoadingSequence: !isLoading,
              setDefaultSequence
            }
      }
    >
      {children}
    </ContextDefaultSequence.Provider>
  );
};

export const useDefaultSequence = () => {
  return useContext(ContextDefaultSequence);
};
