import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  SimpleGrid,
  Spinner,
  Text
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { Form, Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuthentication } from "../../auth/auth";
import { FlexColumn, FlexRow } from "../../components/layout/Flex/Flex";
import { useGlobalTxt } from "../../i18n/useGlobalTxt";
import { PopupTermsAndConditions } from "./TermsAndConditions";
import { EStatusSignUp, useSignUp } from "./useSignUp";

export const translations = {
  [ELocale.de_DE]: {
    already_have_an_account: "Sie haben bereits ein Konto?",
    create_an_account: "Konto erstellen",
    i_have_read_and_accept_the: "Für die Nutzung userer digitalen Angebote gelten unsere",
    invalid_email: "Ungültige Email.",
    invalid_email_already_in_use: "Diese Email hat schon ein Konto.",
    invalid_password_must_contain_number: "Passwort muss minimum eine Siffer einhalten.",
    invalid_password_must_contain_lowercased_letter:
      "Passwort muss minumum ein kleines Buchstabe einhalten.",
    invalid_password_must_contain_uppercased_letter:
      "Passwort muss minumum einen großen Buchstaben einhalten.",
    invalid_password_too_short: "Zu kurz! Minimum 6 Buchstaben.",
    log_in: "Einloggen",
    terms_and_conditions: "AGB",
    try_for_free: "Kostenlos ausprobieren"
  },
  [ELocale.en_US]: {
    already_have_an_account: "Already have an account?",
    create_an_account: "Create an account",
    invalid_email: "Invalid email",
    invalid_email_already_in_use: "Email is already in use.",
    invalid_password_must_contain_number: "Password must contain at least one number",
    invalid_password_must_contain_lowercased_letter:
      "Password must contain at least one lowercased letter",
    invalid_password_must_contain_uppercased_letter:
      "Password must contain at least one uppercased letter",
    invalid_password_too_short: "Password must be at least 6 characters",
    i_have_read_and_accept_the: "I have read and accept the ",
    log_in: "Log in",
    terms_and_conditions: "terms and conditions",
    try_for_free: "Try for free"
  },
  [ELocale.es_ES]: {
    already_have_an_account: "¿Ya tienes cuenta?",
    create_an_account: "Crear una cuenta",
    invalid_email: "Correo electrónico inválido",
    invalid_email_already_in_use: "Email is already in use.",
    invalid_password_must_contain_number: "Password must contain at least one number",
    invalid_password_must_contain_lowercased_letter:
      "Password must contain at least one lowercased letter",
    invalid_password_must_contain_uppercased_letter:
      "Password must contain at least one uppercased letter",
    invalid_password_too_short: "Password must be at least 6 characters",
    i_have_read_and_accept_the: "He leído y aceptado los términos y condiciones",
    terms_and_conditions: "Términos y Condiciones",
    try_for_free: "Pruébelo Gratis"
  }
};

const getInitialFormValues = () =>
  process.env.NODE_ENV === "production"
    ? {
        email: "",
        password: "",
        agreesToTermsAndConditions: false
      }
    : {
        email: "",
        password: "imal123",
        agreesToTermsAndConditions: true
      };

type TInputName = "email" | "password";

type TGetInputStatus = (inputName: TInputName) => boolean;

export const SignUp = () => {
  /* Replace with useDisclosure? */
  const [isShowingTerms, setIsShowingTerms] = useState(false);
  const { signUp, status: signUpStatus } = useSignUp();
  const { authStatus } = useAuthentication();
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();
  const navigate = useNavigate();

  /* TODO?: replace with zod? */
  const signUpSchema = Yup.object().shape({
    email: Yup.string().email(txt.invalid_email).required(" ").lowercase(),
    password: Yup.string().min(6, txt.invalid_password_too_short).required(" "),
    agreesToTermsAndConditions: Yup.bool().oneOf([true]).required()
  });

  /* TODO: use react-hook-form instead of formik... */
  /* See PopupCreaterUser (already using read-hook-form there?). */
  const formik = useFormik({
    initialValues: getInitialFormValues(),
    onSubmit: () => {},
    validationSchema: signUpSchema
  });

  const isInputInvalid: TGetInputStatus = (inputName) =>
    formik.touched[inputName]! && !!formik.errors[inputName]!;

  useEffect(() => {
    if (signUpStatus === "success" || authStatus === "authenticated")
      navigate("/app", { replace: true });
  }, [signUpStatus, authStatus]);

  /* Initially set formik.isValid to false to disable sign up button. */
  useEffect(() => {
    formik.validateForm();
  }, []);

  /* TODO: make the container a grid? Then can "center" the content; not */
  /* really center though, as want content positioned slightly */
  /* above vertical center. */
  return (
    <SimpleGrid
      mx="auto"
      maxW="500px"
      minH="100vh"
      minW="500px"
      templateRows="3fr auto 5fr"
      w="60vw"
    >
      <Box />
      <FlexColumn>
        <PopupTermsAndConditions
          isOpen={isShowingTerms}
          onClose={() => {
            setIsShowingTerms(false);
          }}
        />
        <Heading>{txt.create_an_account}</Heading>
        <Formik initialValues={getInitialFormValues()} onSubmit={() => {}}>
          <Form>
            {(["email", "password"] as TInputName[]).map((fieldName, i) => (
              // Find out if chakra input has isValid equivalent.
              <FormControl
                id={fieldName}
                isInvalid={isInputInvalid(fieldName)}
                isRequired
                key={i}
                onChange={formik.handleChange}
                onFocus={formik.handleBlur}
                mt="2rem"
                placeholder={txt_g[fieldName]}
                value={formik.values[fieldName]}
                w="parent"
              >
                <FormLabel>{txt_g[fieldName]}</FormLabel>
                <Input
                  bg="white"
                  name={fieldName}
                  placeholder={txt_g[fieldName]}
                  type={fieldName === "password" ? "password" : ""}
                />
                <FormErrorMessage color="red">{formik.errors[fieldName]}</FormErrorMessage>
              </FormControl>
            ))}

            <FormControl id="user_agreement" isRequired>
              <FlexRow m="1.5rem 0" alignItems="center" justify="center">
                <Text>
                  {`${txt.i_have_read_and_accept_the} `}
                  <Link onClick={() => setIsShowingTerms(true)}>{txt.terms_and_conditions}</Link>.
                </Text>
                <Checkbox
                  bg="white"
                  defaultChecked={formik.values.agreesToTermsAndConditions}
                  ml="1rem"
                  name="agreesToTermsAndConditions"
                  onChange={formik.handleChange}
                  onFocus={formik.handleBlur}
                  isRequired
                  size="lg"
                  type="checkbox"
                />
              </FlexRow>
            </FormControl>

            <FlexColumn m="auto" w="80%">
              {signUpStatus === "error:auth/email-already-in-use" && (
                <Text textAlign="center" color="red">
                  {txt.invalid_email_already_in_use}
                </Text>
              )}
              <Button
                _hover={{
                  cursor: formik.isValid ? "pointer" : "default"
                }}
                disabled={!formik.isValid}
                mt="1rem"
                onClick={() =>
                  signUp({
                    email: formik.values.email,
                    password: formik.values.password
                  })
                }
                size="lg"
              >
                {signUpStatus === EStatusSignUp.LOADING ? <Spinner /> : txt_g.create_user}
              </Button>
              <Text mt="5rem">{txt.already_have_an_account}</Text>
              <Button onClick={() => navigate("/login")}>{txt_g.sign_in}</Button>
            </FlexColumn>
          </Form>
        </Formik>
      </FlexColumn>
      <Box />
    </SimpleGrid>
  );
};
