import { TSound } from "@imaldev/imal-factory/abc";
import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { mkUUID, UUID } from "../../utilsTS/uuid/uuid";
import { sequences_de_DE } from "./locales/de_DE/de_DE";

/* TODO: each sound sequence should have prefered typeface. wyut? */
/* Maybe prefix some ids by the locale they belong to? */
export type SoundSequence = {
  bookPicture?: string;
  displayName: string;
  id: UUID;
  name: string;
  sounds: TSound[];
};

const phSeq: SoundSequence = {
  displayName: "placeholder",
  id: "placeholder",
  name: "placeholder",
  /* Just make default alphabet all english letters? */
  sounds: "placeholder".split("")
};

export const newSequence = (pSeq?: Partial<SoundSequence>) => {
  const seq: SoundSequence = {
    ...phSeq,
    ...pSeq,
    id: mkUUID()
  };
  return seq;
};

const getBookSequences = (l: ELocale) => {
  const seqsByLocale: LocaleRecord<SoundSequence[]> = {
    [ELocale.de_DE]: sequences_de_DE
  };
  return seqsByLocale[l] ?? [];
};

/* Shouldn't need to pass locale, as seq id is uuid; but splitting seqs by locale */
/* make sequence managing simpler? */
export const getBookSequence = (l: ELocale, sequenceId: UUID) => {
  return getBookSequences(l).find((s) => s.id === sequenceId);
  // return placeholderSequence;
};

/* Better to have fns, `getThing(l: ELocale)`? */
/* Feel like getThing is better... use[xyz] is best for when want to listen to something */
/* which can change. */
export const useBookSequences = () => {
  const { locale } = useLocale();
  return { sequences: getBookSequences(locale) };
};
