import { ELocale, LocaleRecord } from "@imaldev/imal-factory/i18n";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { UUID } from "../../utilsTS/uuid/uuid";
import { germanBayern } from "./germanBayern/germanBayern";
import { germanGrundschrift } from "./germanGrundschrift/germanGrundschrift";
import { germanNorth } from "./germanNorth/germanNorth";
import { germanSouth } from "./germanSouth/germanSouth";
import { nordicSansSerif } from "./nordicSansSerif/nordicSansSerif";
import { nordicSerif } from "./nordicSerif/nordicSerif";
import { ETaskFontVariant, ETypeface, newBlankFont, TaskFont, TTaskTypeface } from "./shared";

/* ===== Typefaces and their fonts for use in TaskSheets. ===== */

/* TODO: rename file to `index.ts`. */

/* Wait a minute... why have this *AND* shared? Couldn't we just merge into 1 module? */
/* Or will we get cyclical deps, then? */

export const taskTypefaceByName: Record<ETypeface, TTaskTypeface> = {
  germanBayern,
  germanGrundschrift,
  germanNorth,
  germanSouth,
  nordicSerif,
  nordicSansSerif
};

/* TODO: potentially return a funny font (which is not used anywhere), instead */
/* of blank font; blank font may make debugging confusing. */
/* TODO?: if typeface doesn't have desired font, return null? Will this break something? */
export const getTaskFont = (typeface: ETypeface, variant: ETaskFontVariant) =>
  taskTypefaceByName[typeface].fonts[variant] ?? newBlankFont(typeface);

/* This one could do two things? hmmm */
/* if pass locale, get tfs for this locale. */
/* if no locale, get all tfs. */

export const getAllTypefaces = () => Object.values(taskTypefaceByName);

export const getTypefaceWithName = (name: ETypeface) => taskTypefaceByName[name];

export const getTypefaceWithId = (id: UUID) =>
  getAllTypefaces().find((tf) => tf.id === id) ?? getAllTypefaces()?.[0];

export const getTypefaceForFont = (font: TaskFont) => taskTypefaceByName[font.typeface]!;

export const taskTypefacesByLocale: LocaleRecord<Array<TTaskTypeface>> = {
  [ELocale.de_DE]: [germanBayern, germanNorth, germanSouth, germanGrundschrift],
  [ELocale.en_US]: [nordicSerif, nordicSansSerif]
};

export const getDefaultTypeface = (locale = ELocale.de_DE) => {
  const defaultTfByLocale: LocaleRecord<ETypeface> = {
    /* TODO: getDefaultFont should reference defaultTf. */
    /* TODO?: create default FontVariant per locale, too? */
    [ELocale.de_DE]: ETypeface.GERMAN_NORTH
  };

  return getTypefaceWithName(defaultTfByLocale[locale] ?? ETypeface.NORDIC_SERIF);
};

/* TODO?: also return (funny font[]) as default values here (for easire debugging)? */
/* TODO: Return values for default locale in such fns. everywhere. */
/* Can get this from factory? */
export const getLocaleTypefaces: (l: ELocale) => TTaskTypeface[] = (l) =>
  taskTypefacesByLocale[l] ?? taskTypefacesByLocale.de_DE;

export const useLocaleTypefaces = () => {
  const { locale } = useLocale();
  return { localeTfs: getLocaleTypefaces(locale) };
};

/* TODO: have some map with defaultFontPerLocale? */
/* This kind of map might be useful to have per sheet? At least */
/* for some sheet types. */

/* Default font will probably vary between sheets. ALSO, default font */
/* can vary between locales within a sheet. */
/* TODO: should use defaultLocale here. */
export const getDefaultFont = (locale = ELocale.de_DE) => {
  const defaultFontByLocale: LocaleRecord<TaskFont> = {
    [ELocale.de_DE]: germanSouth.fonts.outlineStartpoints!
  };
  return germanSouth.fonts.regular!;
};

/* Does it make sense to create fn getFont(typefaceName, variantName)?. */
