import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  Wrap
} from "@chakra-ui/react";
import { latinAlphabet, SoundSequence, TSound } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { PRecord } from "@imaldev/imal-factory/ts";
import { ETypeface } from "@imaldev/imal-react-ui/abc";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { InputLabel } from "@material-ui/core";
import {
  assoc,
  compose as x,
  curry,
  equals,
  head,
  keys,
  last,
  not,
  pipe as p,
  prop,
  range,
  takeLastWhile,
  takeWhile,
  toPairs
} from "ramda";
import { Reducer, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { WithChildren } from "../../../../../../utils/utilsReact/types";
import { ESheet } from "../../../SheetCreator/taskSheets/shared/misc";
import { sheetConfigs, TaskSheet } from "../../../SheetCreator/taskSheets/TaskSheet";
import { SequenceCreator } from "./SequenceMaker";
import { Maybe, MobileLayout, ProgressBar, SignUpLayout, signUpPages, SignUpView } from "./shared";

/* How to set data. */
/* One config object -> this can be an intersection of types for each of the views? */

/* Maybe do with zustand first, and then do some reading about jotai on the side. */
/* Jotai may actually work nicely? */

/* This looks alright. */
type SignUpConfig = {
  personalia: Personalia;
  font: ConfTypeface;
  sequence: ConfSequence;
  firstPage: ConfFirstPage;
};

type PersonaliaKey =
  | "firstName"
  | "lastName"
  | "country"
  | "district"
  | "schoolName"
  | "teacherRole";

type PersonaliaEnabledStatus = "required" | "hidden" | "optional";

type Personalia = PRecord<PersonaliaKey, string>;

/* Map over this when rendering form. */
const fieldConfigs: PRecord<ELocale, Record<PersonaliaKey, PersonaliaEnabledStatus>> = {
  [ELocale.de_DE]: {
    firstName: "optional",
    lastName: "optional",
    country: "required",
    district: "required",
    schoolName: "optional",
    teacherRole: "optional"
  }
};

const isPersonaliaValid = (personalia: Partial<Personalia>) => (locale: ELocale) => {
  const localeInputs = fieldConfigs[locale];
  if (!localeInputs) return false;
  return toPairs(localeInputs)
    .filter(([, kind]) => kind === "required")
    .every(([key]) => personalia[key] !== "");
};

type PersonaliaStore = Personalia & {
  setValue: (key: keyof Personalia, value: Personalia[typeof key]) => void;
  derived: {
    canSubmit: (locale: ELocale) => boolean;
  };
};

/* How to use locale here? */
const usePersonalieStore = create<PersonaliaStore>()(
  immer((set, get) => ({
    country: "", // Enum or sth?
    district: "", // depends on country? Can validation even check this? probably na.
    firstName: "",
    lastName: "",
    schoolName: "",
    teacherRole: "",

    setValue: (key, value) =>
      set((state) => {
        state = assoc(key, value, state);
      }),

    derived: {
      canSubmit: isPersonaliaValid(get())
    }
  }))
);

type ConfTypeface = {
  typeface: ETypeface;
};

type ConfSequence = {
  sequence: SoundSequence["id"];
};

type ConfFirstPage = {
  sheet: ESheet;
};

/* Will this even be used? */
const initialState: SignUpConfig = {
  firstPage: {
    sheet: ESheet.ONE_LARGE_SOUND
  },
  font: {
    typeface: ETypeface.GERMAN_BAYERN
  },
  personalia: {
    country: "",
    district: "",
    firstName: "",
    lastName: "",
    schoolName: "",
    teacherRole: ""
  },
  sequence: {
    sequence: "foobar"
  }
};

/* The initial data should be a merge between "empty" initial state object, */
/* and already saved values from an incomplete session. */
/* Getting initial state can be async? */

export const MobileSignUp = () => {
  const [activeView, setActiveView] = useState<SignUpView>("greeting");

  const View = prop(activeView, {
    greeting: ViewWelcome,
    personalData: ViewPersonalDetails,
    font: ViewFont,
    sequence: ViewSequence,
    selectFirstPage: ViewSelectFirstPage
  });

  return (
    <MobileLayout>
      <Flex flexDir="column" bg="blue.200" h="100%">
        {activeView !== "greeting" && <ProgressBar activeView={activeView} />}
        <View navigate={p(curry(getViewForNavigation)(activeView), setActiveView)} />
      </Flex>
    </MobileLayout>
  );
};

type CommonViewProps = { navigate: (direction: Direction) => void };
type Direction = "backwards" | "forwards";

const getViewForNavigation = (currentView: SignUpView, direction: Direction): SignUpView => {
  let view: Maybe<SignUpView>;
  if (direction === "backwards")
    view = p(
      () => keys(signUpPages),
      takeWhile(x(not, equals(currentView))), // ... here.
      last
    )() as Maybe<SignUpView>;
  else if (direction === "forwards")
    view = p(
      () => keys(signUpPages),
      takeLastWhile(x(not, equals(currentView))),
      head
    )() as Maybe<SignUpView>;
  return view ?? currentView;
};

const ViewWelcome = (props: CommonViewProps) => {
  return (
    <Flex flexDir="column" h="100%">
      <Stack boxSizing="content-box" px="4em" py="4em" spacing="2em">
        <Text as="h2">Welcome!</Text>
        <Text fontSize="xl">
          Complete this setup process to begin creating and printing worksheets.
        </Text>
        <Text fontSize="xl">You can freely change the settings you select here at any time.</Text>
      </Stack>
      <NavContainer>
        <Box />
        <Button onClick={() => props.navigate("forwards")}>Start</Button>
      </NavContainer>
    </Flex>
  );
};

const NavContainer = (props: WithChildren) => {
  return (
    <SimpleGrid columns={2} gap="1.8em" p=".5em" px="1.5em" bg="blue.400" mt="auto">
      {props.children}
    </SimpleGrid>
  );
};

/* TODO: people from different countries may have different expectations of which personal details */
/* to have to provide when signing up for a new application. */
/* Can have map for each locale which specifies which details should be collected per locale. */
const ViewPersonalDetails = (props: CommonViewProps) => {
  /* Wonder if translations too could be tied to field configs. */
  const { txt } = useTxt({
    de_DE: {
      firstName: "Vorname",
      lastName: "Nachname",
      schoolName: "Schulname",
      district: "Bundesland",
      country: "Land",
      educatorRole: "Beruf"
    }
  });

  return (
    <SignUpLayout
      content={
        <Stack spacing="2.5em" px="2em" py="4em" boxSizing="content-box">
          <SimpleGrid columns={2} gap="1.5em" maxW="inherit">
            <Stack>
              <InputLabel>{txt.firstName}</InputLabel>
              <Input w="inherit" bg="white" />
            </Stack>
            <Stack>
              <InputLabel>{txt.lastName}</InputLabel>
              <Input w="inherit" bg="white" />
            </Stack>
          </SimpleGrid>
          <InputLabel>{txt.country}</InputLabel>
          <Input bg="white" w="inherit" />
          <InputLabel>{txt.district}</InputLabel>
          <Input bg="white" w="inherit" />
          <InputLabel>{txt.schoolName}</InputLabel>
          <Input bg="white" w="inherit" />
          <InputLabel>{txt.educatorRole}</InputLabel>
          <Input bg="white" w="inherit" />
        </Stack>
      }
      bottomNavBar={
        <NavContainer>
          <Button onClick={() => props.navigate("backwards")}>Back</Button>
          <Button onClick={() => props.navigate("forwards")}>Next</Button>
        </NavContainer>
      }
    />
  );
};

/* How does this get access to set sequence? */
function ViewFont(props: CommonViewProps) {
  return (
    <SignUpLayout
      content={
        <Stack spacing="2.5em" px="2em" py="4em" boxSizing="content-box">
          {[{ name: "iMAL A" }, { name: "iMAL B" }, { name: "iMAL C" }, { name: "iMAL D" }].map(
            (values, i) => (
              <FontCard key={i} {...values} sounds={latinAlphabet} />
            )
          )}
        </Stack>
      }
      bottomNavBar={
        <NavContainer>
          <Button onClick={() => props.navigate("backwards")}>Back</Button>
          <Button onClick={() => props.navigate("forwards")}>Next</Button>
        </NavContainer>
      }
    />
  );
}

/* TODO?: add zooming in on letters on hover/press. */
const FontCard = (props: { name?: string; sounds?: TSound[] }) => {
  return (
    <Card as={Wrap} w="100%">
      <Stack p="1.15em">
        <Heading m="0em" mb=".6em">
          {props.name}
        </Heading>
        <Wrap spacing=".8em">
          {props.sounds?.map((sound) => (
            <Center
              boxSize="2em"
              bg="blue.100"
              borderRadius=".5em"
              boxShadow="sm"
              key={sound}
              p=".35em"
            >
              <Text fontSize="1.8em">{sound}</Text>
            </Center>
          ))}
        </Wrap>
      </Stack>
    </Card>
  );
};

const Card = chakra(Box, { baseStyle: { bg: "gray.50", borderRadius: ".3em", boxShadow: "lg" } });

/* TODO: sticky(?) container at top with button to create custom sequence. */

/* Possible we can omit creating custom sequence in the beginning, until have */
/* other things in the app working ok. */
/* Can at least post pone it; or work on both things simultainously, though prioritizing */
/* app features? */

const ViewSequence = (props: CommonViewProps) => {
  const [selectedSeq] = useState(1); // TODO: id of sequence
  const sequenceCreator = useDisclosure();
  return (
    <SignUpLayout
      content={
        <Flex flexDir="column" flex={1} h="100%">
          <Center bg="blue.300" mx="auto" pt=".8em" pb="1.25em" w="100%">
            <Button onClick={sequenceCreator.onOpen}>Create sequence</Button>
          </Center>
          <Stack flexDir="column" flex={1} overflowY="auto" p=".85em">
            {range(0, 10).map((n, i) => (
              <SequenceCard
                image="TODO"
                sequenceName="ABC der Tiere"
                isSelected={n === selectedSeq}
                key={i}
              />
            ))}
          </Stack>
          <SequenceCreator {...sequenceCreator} />
        </Flex>
      }
      bottomNavBar={
        <NavContainer>
          <Button onClick={() => props.navigate("backwards")}>Back</Button>
          <Button onClick={() => props.navigate("forwards")}>Next</Button>
        </NavContainer>
      }
    />
  );
};

/* Will flex children resize if dimensions of their children changes? */
/* How will this function on tiny mobile phones (ie. may have to scroll to see all sounds in a container)? */
/* Maybe also experiment with making sequence creation a 2-step process: */
/* - select sounds */
/* - reorder sounds */
/* Editing the selected sounds should be very easy to do. */
/* Propably ~50% of users will change the sounds to be included in the sequence. */

/* Should be possible to create custom sequence. */
/* Custom sequences created here will be saved, even if user changes their mind after having created */
/* a custom sequence, and decides to use a book sequence instead. */

/* Create new experimental view for creating nice to use DnD for sounds and their containers. */

/* How to get needed data for this... */
/* Use firebase storage for... various images and such. */
/* And store sequence data (and words etc etc in firestore -> create an interface for this */
/* so will be easier to change implementation. */
/* How to get path of image? */
/* Base64? */
/* path? */
/* dict lookup by name? */
const SequenceCard = (props: {
  sequenceName: string;
  image: string;
  isSelected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Card
      as={Flex}
      border="4px solid"
      borderColor={props.isSelected ? "green.300" : "white"}
      onClick={() => {}}
      p=".5em"
    >
      <Box bg="blue.600" h="6em" w="8em">
        {props.image}
      </Box>
      <Box bg="purple.400" h="6em" w="100%">
        {props.sequenceName}
      </Box>
    </Card>
  );
};

/* Implementation thoughts: */
/* - layoutEffect to calculate row heights (use existing sheet height-width ratio constant). */

const ViewSelectFirstPage = (props: CommonViewProps) => {
  const [selectedSheet, setSelectedSheet] = useState<Maybe<ESheet>>(null);
  const navigate = useNavigate();
  return (
    <SignUpLayout
      content={
        <Flex flexDir="column" pos="relative">
          {/* TODO: make title sticky. Can use same css as ViewSequence (button for create sequence). */}
          <Heading pos="static" size="lg" mx="auto">
            Choose first page for booklet
          </Heading>
          <PageGrid selectedSheet={selectedSheet} onSelectSheet={setSelectedSheet} />
        </Flex>
      }
      bottomNavBar={
        <NavContainer>
          <Button onClick={() => props.navigate("backwards")}>Back</Button>
          <Button
            disabled={not(selectedSheet)}
            onClick={() => {
              /* TODO: create the booklet before entering. */
              /* How to get data of new booklet inside editor? */
              /* ...with zustand, can access store without wrappers...? */
              /* Or pass state of new booklet as url params? */
              navigate("../booklet-editor");
            }}
          >
            New booklet
          </Button>
        </NavContainer>
      }
    />
  );
};

/* Probably don't need this abstraction. */
const PageGrid = (
  props: WithChildren & { onSelectSheet: (sheet: ESheet) => void; selectedSheet: Maybe<ESheet> }
) => {
  return (
    <SimpleGrid bg="green.200" columns={3} gap=".8em" p="1em" h="100%">
      {toPairs(sheetConfigs).map(([sheetName, conf], i) => (
        <Box
          border="8px solid"
          borderColor={sheetName === props.selectedSheet ? "green.300" : "gray.300"}
          boxSizing="border-box"
          display="block"
          onClick={() => props.onSelectSheet(sheetName as ESheet)}
          width="4em"
          height="9em"
          key={i}
        >
          <TaskSheet sheetProps={conf.menuSheets.de_DE} />
        </Box>
      ))}
    </SimpleGrid>
  );
};

type UseDOMSheetDimensions = (
  arg:
    | { kind: "ref"; ref: React.MutableRefObject<HTMLDivElement> }
    | { kind: "width" | "height"; value: number }
) => [{ width: number; height: number }, React.MutableRefObject<HTMLDivElement>];

export const useDOMSheetDimensions: UseDOMSheetDimensions = (arg) => {
  const returnRef = useRef<HTMLDivElement>(null!);
  const WH_RATIO = 1.41;

  const [dimensions, setDimensions] = useState({
    width: arg.kind == "width" ? arg.value : 0,
    height: arg.kind == "height" ? arg.value : 0
  });

  /* Must store values in state? */
  useEffect(() => {
    const updateDimensions = () => {};
    /* TODO: onorientationchange, onresize */
    return () => console.log("unmounting");
  }, []);

  return [{ width: 100, height: 141 }, returnRef] as ReturnType<UseDOMSheetDimensions>;
};

/* Maybe design of letter/sound container will become clear after start working on sequence creator. */
/* Thoughts SoundCreator:  */
/*   Create a few different designs? */
/* New sequence should be at the bottom or top? */
/* Create sequence using a pre-existing sequence as a template? */
/* Dragging or clicking the most common action? */
/* Both should be comfortable. */
/* Dragging will be hardest to make comfortable, so focus on solving this as good as possible */
/* New sequence at the top? Easy to click (will likely be doing clicking on sounds the most, but dragging for */
/* the few sounds which were insterted into the new sequence in the wrong order, will still be comfortable with the main thumb. */
