import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Text,
  useDimensions,
  UseDisclosureProps,
  Wrap
} from "@chakra-ui/react";
import { latinAlphabet } from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { append, not, pick, pipe as p, prop, propEq, without } from "ramda";
import { renameKeys } from "ramda-adjunct";
import { useRef, useState } from "react";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { MotionBox } from "../../../../../../components/MotionBox";
import { useLogValue } from "../../../../../../utils/utilsReact/useLogValue";
import { sleep } from "../../../../../../utils/utilsTS/async";
import { GenericDrawer } from "../../shared/components/GenericDrawer";
import { Maybe } from "./shared";

/* Props can use (some?) props of generic drawer? */
/* Maybe should be taller? Can calc required height somehow? */

/* Have to draw sketches of how this should look like and function? */

/* List of features needed for a nice experience: */
/* - drop container preview */
/* - change height of drop containers, if moving a sound changes the number of rows */
/* in the DnD grid container. changing of height should be animated. */

/* Maybe dynamic height stuff will be easier to implement when DnD is already working. */

/* Seems like react-dnd-kit is exactly what we are looking for: */
/* https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/presets-sortable-multiple-containers--grid */

/* What is api for editor... */
/* - move sound to new seq */
/* - remove sound from new seq */
/* - move to new seq with index  */
/* - move to new seq at the end */

/* One store for whole sign up process? */
/* Think about having a single store for all sign up. */

type SoundSequence = {
  id: string;
  name: string;
  sounds: string[];
};

/* Action for using a sequence as template for new sequence. */
/* Any params? */
/* Does it make sense to include logic for getting locale sequences? */
/* ...logic for setting sounds to be equal to that of a book sequence (template). */

type StoreSequenceView = {
  loadAvailableSequences: (locale: ELocale) => Promise<void>;
  availableSequences: SoundSequence[];
  selectSequence: () => void;
  selectedSequence: Maybe<SoundSequence["id"]>;
  seqMaker: {
    name: string;
    saveAndSelect: () => void;
    soundsNotUsed: string[];
    soundsNewSequence: string[];
    setName: (newName: string) => void;
    removeFromSequence: (sound: string) => void;
    setBookSeqAsTemplate: (bookId: string) => void;
    computed: {
      canSaveSequence: () => boolean;
      getSequence: () => SoundSequence;
    };
  };
};

/* Can jotai atom depend on other atoms? */

/* Will store merge things? */
const useStore = create<StoreSequenceView>()(
  immer((set, get) => ({
    loadAvailableSequences: async () => {
      await sleep(500);
      set({ availableSequences: [] });
    },
    availableSequences: [], // how to trigger getting data from api and stuff
    selectedSequence: null,
    selectSequence: () => {},
    seqMaker: {
      /* Values */
      name: "",
      soundsNotUsed: [],
      saveAndSelect: () => {},
      soundsNewSequence: [],
      /* Actions */
      setName: (newName) => set(({ seqMaker }) => (seqMaker.name = newName)),
      removeFromSequence: (sound) =>
        set(({ seqMaker: sc }) => {
          sc.soundsNewSequence = without([sound], sc.soundsNewSequence);
          sc.soundsNotUsed = append(sound, sc.soundsNotUsed);
        }),
      setBookSeqAsTemplate: (bookId) =>
        set((state) => {
          /* Need locale here to get sounds which are not used in book... */
          const seq = state.availableSequences.find(propEq("id", bookId));
          if (!seq) return;
          state.seqMaker.soundsNewSequence = seq.sounds;
        }),

      computed: {
        canSaveSequence: () => {
          const state = get();
          return state.seqMaker.name !== "" && state.seqMaker.soundsNewSequence.length > 0;
        },
        getSequence: p(
          get,
          prop("seqMaker"),
          pick(["name", "soundsNewSequence"]),
          renameKeys({ soundsNewSequence: "sounds" })
        ) as () => SoundSequence
      }
    }
  }))
);

type Props = UseDisclosureProps & {
  onSave?: (sequence: SoundSequence) => void; // maybe can return a promise?
};

/* Should be dumb? */
export const SequenceCreator = (props: Props) => {
  const [seqName, setSeqName] = useState("");
  const [sounds, setSounds] = useState(latinAlphabet);

  const store = useStore();

  /* This is tmp stuff. */
  const ref = useRef<HTMLDivElement>(null!);
  const [height, setHeight] = useState(200);
  const dims = useDimensions(ref);
  useLogValue(dims);

  return (
    <GenericDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      h="80vh"
      title={
        <Flex gap="2em">
          <Input
            bg="white"
            onChange={(e) => setSeqName(e.currentTarget.value)}
            value={seqName}
            mr="2em"
            placeholder="Name of sequence"
            size="lg"
          />
          {/* Why is this thing squashed vertically? */}
          <Center
            bg="blue.500"
            borderRadius="50%"
            boxShadow="lg"
            height="50px"
            width="60px"
            mr="4em"
          >
            <Text as="b" color="yellow.200" fontSize="3xl">
              ?
            </Text>
          </Center>
        </Flex>
      }
    >
      <Flex
        bg="gray.200"
        borderTopRadius="1.25em"
        flex={1}
        flexDir="column"
        w="100%"
        h="100%"
        ref={ref}
      >
        {/* Start content. */}
        <Flex flexDir="column" h="100%" w="100%">
          <MotionBox
            animate={{ height: height }}
            transition={{ duration: 0.4 }}
            bg={sounds.length % 2 === 0 ? "red.200" : "green.200"}
            p="1em"
          >
            <Wrap bg="green.100" h="fit-content">
              {sounds.map((sound, i) => (
                <Sound key={i} value={sound} />
              ))}
            </Wrap>
          </MotionBox>
          <Box bg="red.100" flex={1}></Box>
        </Flex>
        <Flex
          bg="green.100"
          alignItems="center"
          justifyContent="space-around"
          w="100%"
          h="5em"
          mt="auto"
        >
          <Button onClick={() => setHeight((prev) => prev + 100)}>Increase height</Button>
          <Button onClick={props.onClose}>Close</Button>
          <Button
            disabled={not(store.seqMaker.computed.canSaveSequence())}
            // props.onSave is stupid in this situation?
            onClick={() => props.onSave?.(store.seqMaker.computed.getSequence())}
          >
            Save & Select
          </Button>
        </Flex>
        {/* End content. */}
      </Flex>
    </GenericDrawer>
  );
};

/* Should implement draggable stuff. */
const Sound = (props: { value: string }) => {
  return (
    <Center bg="blue.500" borderRadius=".3em" boxShadow="md" boxSize="3.25em">
      <Text color="white" fontSize="xl">
        {props.value}
      </Text>
    </Center>
  );
};
