import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { assoc } from "ramda";
import { useMemo, useState } from "react";
import Div100vh from "react-div-100vh";
import { BsArrowLeftCircle, BsThreeDots } from "react-icons/bs";
import { FaRegSave, FaSave } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { ImExit } from "react-icons/im";
import { TiDocumentAdd } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { ETypeface } from "../../../../../../utils/utilsiMAL/fonts/shared";
import {
  configOneLargeSound,
  mkOneLargeSound
} from "../../../SheetCreator/taskSheets/OneLargeSound";
import { sheetConfigs, TaskSheet } from "../../../SheetCreator/taskSheets/TaskSheet";
import { SoundSelector, SoundsSelector } from "../../shared/components";
import { GenericDrawer } from "../../shared/components/GenericDrawer";
import { Booklet } from "../../shared/data/entities/booklet";
import { Paper } from "../../shared/icons";
import { DownloadPdf } from "../../shared/icons/DownloadPdf";
import { NavButton } from "../sheet-editor/shared";

/* What is the thing which was thinking about before... */
/* Storing locale and other assets inside booklet, */
/* such that updates to sheets can be done without relying on things elsewhere. */

export const BookletCreator = () => {
  const navigate = useNavigate();
  const [isOpenSettingsDrawer, setIsOpenSettingsDrawer] = useState(false);
  const [isAddingNewSheet, setIsAddingNewSheet] = useState(false);
  const [isOpenExitMenu, setIsOpenExitMenu] = useState(false);

  /* Maybe need to do math for dimensions of things... */
  return (
    <Div100vh>
      <DrawerBookletSettings
        isOpen={isOpenSettingsDrawer}
        labelSaveButton="Save"
        onClose={() => setIsOpenSettingsDrawer(false)}
        onSave={() => {}}
        title="Edit booklet settings"
      />
      <DrawerAddNewSheet isOpen={isAddingNewSheet} onClose={() => setIsAddingNewSheet(false)} />
      {/* TODO: abstact. */}
      <GenericDrawer
        h="5em"
        isOpen={isOpenExitMenu}
        noHeader
        onClose={() => setIsOpenExitMenu(false)}
      >
        {/* TODO: fix styling for these icons. */}
        <SimpleGrid columns={3} gap="3em" pb="0" mx="auto">
          {/* Find icons for these. */}
          {/* icon download pdf: */}
          {/* https://www.flaticon.com/premium-icon/download_4529917?term=pdf%20download&page=1&position=17&page=1&position=17&related_id=4529917&origin=search */}
          <Stack alignItems="center" onClick={() => navigate("..")}>
            <Icon as={ImExit} bg="white" borderRadius="50%" fontSize="20" p=".7em" />
            <Text fontSize="12" fontWeight="bold">
              Exit without saving
            </Text>
          </Stack>
          <Stack alignItems="center" onClick={() => navigate("..")}>
            <Flex>
              <Icon as={FaRegSave} bg="gray.200" fontSize="20" />
              <Icon as={ImExit} bg="gray.200" fontSize="20" />
            </Flex>
            <Text fontSize="12" fontWeight="bold">
              Save and exit
            </Text>
          </Stack>
          {/* TODO: think about how downloading flow should work. */}
          <Stack alignItems="center">
            <Icon as={DownloadPdf} bg="gray.200" fontSize="22" />
            <Text fontSize="12" fontWeight="bold">
              Download PDF
            </Text>
          </Stack>
        </SimpleGrid>
      </GenericDrawer>
      <Flex flexDir="column" bg="green.200" h="100%">
        <SimpleGrid columns={3} gap=".5em" autoRows="10em" w="100%" mx=".5em">
          <GridItem onClick={() => navigate("../editor")}>
            <TaskSheet sheetProps={configOneLargeSound.fnMkSheet()} />
          </GridItem>
          <GridItem>
            <TaskSheet
              sheetProps={sheetConfigs.soundPoster.fnMkSheet({
                pSheet: { focusedSound: "r", words: [{ value: "Rose" }] }
              })}
            />
          </GridItem>
          <GridItem>
            <TaskSheet sheetProps={sheetConfigs.writeSoundRows.fnMkSheet()} />
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          bg="blue.200"
          columns={4}
          columnGap=".1em"
          w="100%"
          h="3.8em"
          mt="auto"
          py=".2em"
        >
          <NavButton
            icon={<Icon fontSize="1.85em" as={BsThreeDots} />}
            onClick={() => setIsOpenExitMenu(true)}
          />
          <NavButton icon={<Icon fontSize="1.4em" as={FaSave} />} label="Save" />
          <NavButton
            // Change icon to this one?
            // https://www.flaticon.com/free-icon/add_2312400?related_id=2312400&origin=search
            icon={<Icon fontSize="1.6em" as={TiDocumentAdd} />}
            label="New sheet"
            onClick={() => {
              setIsAddingNewSheet(true);
              // navigate("../editor");
            }}
          />
          {/* TODO: book with settings icon. */}
          {/* https://www.flaticon.com/free-icon/ebook_865507?related_id=865507&origin=search */}
          <NavButton
            icon={
              <Box bg="inherit" pos="relative">
                <Icon fontSize="1.6em" as={Paper} />
                <Icon
                  bg="gray.200"
                  borderRadius="50%"
                  fontSize="1em"
                  as={FiSettings}
                  pos="absolute"
                  top="14px"
                  right="-3px"
                />
              </Box>
            }
            label="Settings"
            onClick={() => setIsOpenSettingsDrawer(true)}
          />
        </SimpleGrid>
      </Flex>
    </Div100vh>
  );
};

/* Don't need to pass onSave? Can just pull in store here as will do same thing everywhere? */
/* But might be some cases in future where we want to "save" to work differently? */
/* Used mutiple places... put in some shared dir? */
/* Possibly also include input for selecting which workbook this sheet belongs to. */
export const DrawerBookletSettings = (props: {
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  onSave?: () => void;
  labelSaveButton?: string;
}) => {
  /* Replace with data from zustand. */
  const [config, setConfig] = useState<
    Partial<
      Pick<Booklet, "name" | "sequence" | "typeface" | "author" | "activeSounds" | "focusedSound">
    >
  >({
    focusedSound: "f",
    name: `unnamed-${new Date(Date.now()).toLocaleString()}`, // Could set name to be the date?
    sequence: "",
    author: "",
    activeSounds: ["f", "o", "b", "a", "r"],
    typeface: ETypeface.GERMAN_GRUNDSCHRIFT
  });

  const [view, setView] = useState<"main" | "sequence" | "focused_sound" | "sounds">("main");

  const BackButton = useMemo(
    () => () =>
      (
        <Icon
          _hover={{ bg: "gray.200" }}
          as={BsArrowLeftCircle}
          fontSize="2xl"
          onClick={() => setView("main")}
        />
      ),
    []
  );

  return (
    <GenericDrawer
      isOpen={props.isOpen ?? true}
      onClose={props.onClose}
      title={<Heading size="lg">{props.title}</Heading>}
    >
      {view === "main" ? (
        <Flex flexDir="column" h="100%">
          <Grid alignItems="center" gap="1.2em" templateColumns="2fr 8fr" p=".4em">
            <Text as="b">Name</Text>
            <Input
              bg="white"
              onChange={(e) => setConfig(assoc("name", e.currentTarget.value))}
              value={config.name}
              w="auto"
            />
            <Text as="b">Letter sequence</Text>
            <Input bg="white" onClick={() => setView("sequence")} value={""} w="auto" />
            <Text as="b">Main letter</Text>
            <Input
              bg="white"
              onClick={() => setView("focused_sound")}
              value={config.focusedSound}
              w="auto"
            />
            <Text as="b">Sounds</Text>
            <Input
              bg="white"
              onClick={() => setView("sounds")}
              value={config.activeSounds}
              w="auto"
            />
          </Grid>
          <Button
            onClick={() => {
              props.onSave?.();
              props.onClose?.();
            }}
            mt="auto"
            mx="auto"
            w="8em"
          >
            {props.labelSaveButton ?? "Start"}
          </Button>
        </Flex>
      ) : view === "sequence" ? (
        <Flex flexDir="column">
          <BackButton />
          sequence
        </Flex>
      ) : view === "focused_sound" ? (
        <Flex flexDir="column" w="100%">
          <Flex alignItems="center" gridGap="1em">
            <BackButton />
            <Text as="b" fontSize="2xl">
              Focused letter
            </Text>
          </Flex>
          <SoundSelector />
        </Flex>
      ) : (
        <Flex flexDir="column" w="100%">
          <Flex alignItems="center" gridGap="1em">
            <BackButton />
            <Box>Sounds</Box>
          </Flex>
          <SoundsSelector />
        </Flex>
      )}
    </GenericDrawer>
  );
};

const DrawerAddNewSheet = (props: { isOpen: boolean; onClose: () => void }) => {
  const { locale } = useLocale();
  return (
    <GenericDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={<Heading size="lg">Select sheet variant</Heading>}
    >
      <Grid
        alignItems="center"
        gap="1.2em"
        templateRows="repeat(10,13em)"
        templateColumns="1fr 1fr 1fr"
        p=".4em"
        bg="blue.200"
        pb=".4em"
        w="100%"
      >
        {Object.values(sheetConfigs).map((conf, i) => (
          <Box
            bg="red.200"
            key={i}
            onClick={() => {
              // start with always adding and going to editor for OLS.
              // To do this properly, need a functional sheet context/state machine.
              console.log("TODO: should go to editor");
            }}
            w="100%"
            h="100%"
          >
            <TaskSheet key={i} sheetProps={conf.menuSheets[locale] ?? mkOneLargeSound()} />
          </Box>
        ))}
      </Grid>
    </GenericDrawer>
  );
};
