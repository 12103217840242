import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { range } from "ramda";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";
import { HScrollList } from "../../../../../../components/ScrollList";
import { MainBottomNav } from "../sheet-editor/shared";

export const Home = () => {
  /* TODO?: Switch from HScrollLists to n-row grids when click a show more-button. */

  /* Each book in a HScrollList should show name and date of booklet. */

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Button as={Link} to="../sign-up">
          Sign up
        </Button>
        {/* Possibly add some padding around all content? (but not around bottom bar). */}
        <Stack>
          <Stack>
            <Heading mb=".2em" ml=".5em" size="h4">
              Saved booklets
            </Heading>
            <HScrollList>
              {range(0, 8).map((_, i) => (
                <Box bg="purple.200" key={i} w="4em" h="5.7em" />
              ))}
            </HScrollList>
          </Stack>

          <Stack>
            <Heading mb=".2em" ml=".5em" size="h4">
              Draft booklets
            </Heading>
            <HScrollList>
              {range(0, 8).map((_, i) => (
                <Box bg="purple.200" key={i} w="4em" h="5.7em" />
              ))}
            </HScrollList>
          </Stack>
        </Stack>
        <MainBottomNav />
      </Flex>
    </Div100vh>
  );
};
