import {
  Button,
  Center,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { callCloudFn } from "../../../../../api/shared";
import { useGlobalTxt } from "../../../../../i18n/useGlobalTxt";

const translations = {
  [ELocale.de_DE]: {
    create_new_user: "Nutzer erstellen",
    email: "Email",
    firstName: "Vorname",
    lastName: "Nachname",
    password: "Passwort",
    reset_form: "Formular entleeren",
    schoolName: "Schulname"
  },
  [ELocale.en_US]: {
    create_new_user: "Create new user",
    email: "Email",
    firstName: "Firstname",
    lastName: "Last tname",
    password: "Password",
    reset_form: "Reset form",
    schoolName: "School name"
  }
};

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  schoolName: string;
};

type TInputName = keyof FormData;

enum EStatusCreateUser {
  ERROR_EMAIL_ALREADY_EXISTS = "auth/email-already-exists",
  LOADING = "loading",
  NOTHING = "nothing",
  SUCCESS = "success"
}

const useCreateUserAsAdmin = () => {
  const [status, setStatus] = useState(EStatusCreateUser.NOTHING);

  type TCreateUser = (args: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    schoolName: string;
  }) => Promise<void>;

  /* TODO: fix typing of httpResult. */
  const createUserAsAdmin: TCreateUser = async (args) => {
    setStatus(EStatusCreateUser.LOADING);
    const httpResult = (await callCloudFn("createUser", args)) as any;
    const status = httpResult.data.result as EStatusCreateUser;
    setStatus(status);
  };

  return {
    createUserAsAdmin,
    clearStatus: () => {
      setStatus(EStatusCreateUser.NOTHING);
    },
    status
  };
};

export const PopupCreateUser = ({
  close,
  isShowing
}: {
  close: () => void;
  isShowing: boolean;
}) => {
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();
  const {
    createUserAsAdmin,
    clearStatus: clearSignUpStatus,
    status: statusCreateUser
  } = useCreateUserAsAdmin();

  const userValidationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    schoolName: Yup.string(),
    email: Yup.string().email(txt_g.invalid_email).required(txt_g.invalid_email),
    password: Yup.string().min(6, txt_g.invalid_password_too_short).required(" ")
  });

  const { control, formState, getValues, handleSubmit, reset, trigger } = useForm<FormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      schoolName: "",
      email: "",
      password: ""
    },
    resolver: yupResolver(userValidationSchema)
  });

  /* Doesn't fire while form in invalid. */
  const onSubmit = handleSubmit((data) => {
    /* Should only run this when is successful. */
    createUserAsAdmin(data);
  });

  /* TODO: finish this.... */
  return (
    <Modal isCentered isOpen={isShowing} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <CloseButton onClick={close} ml="auto" />
        </ModalHeader>
        <ModalBody boxSizing="content-box">
          <Flex flexDir="column" w="100%">
            <Heading as="b" textAlign="center" mb=".3rem">
              Konto erstellen
            </Heading>
            <form onSubmit={onSubmit}>
              {(["firstName", "lastName", "schoolName", "email", "password"] as TInputName[]).map(
                (inputName, i) => (
                  <Controller
                    control={control}
                    key={i}
                    name={inputName}
                    render={({ field }) => (
                      <FormControl
                        id={inputName}
                        isInvalid={
                          formState.touchedFields[inputName] &&
                          formState?.errors[inputName] &&
                          !!formState?.errors[inputName]?.message
                        }
                        {...(["email", "password"].includes(inputName)
                          ? { isRequired: true, height: "6rem" }
                          : {})}
                        mt="1rem"
                        onChange={(e) => {
                          trigger();
                          console.log("trigger");
                        }}
                      >
                        <FormLabel>{txt[inputName]}</FormLabel>
                        <Input
                          {...field}
                          autoComplete="new-passord"
                          boxSizing="border-box"
                          disabled={statusCreateUser === EStatusCreateUser.SUCCESS}
                        />
                        <FormErrorMessage>
                          {`${formState.errors[inputName]?.message}.`}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                )
              )}
              {/* TODO: Make this a grid. text is empty if no action has been done. */}
              <Grid
                gap="1rem"
                templateColumns="1fr 1fr"
                templateRows="max(1fr,4rem) 2fr"
                m="1rem"
                mt="0rem"
              >
                <GridItem as={Center} minH="3rem" colSpan={2}>
                  {statusCreateUser === "success" ? (
                    <Text as="b" color="green" h="fit-content" alignSelf="center">
                      {txt_g.user_created_successfully(getValues().email)}
                    </Text>
                  ) : (
                    statusCreateUser === EStatusCreateUser.ERROR_EMAIL_ALREADY_EXISTS && (
                      <Text as="b" color="red" textAlign="center">
                        {txt_g.error_email_already_exsists}
                      </Text>
                    )
                  )}
                </GridItem>
                {/* TODO: fix button default styles. */}
                <Button
                  disabled={statusCreateUser !== EStatusCreateUser.NOTHING}
                  type="submit"
                  w="100%"
                  _hover={{ bg: "" }}
                >
                  {statusCreateUser !== EStatusCreateUser.LOADING ? (
                    txt.create_new_user
                  ) : (
                    <Spinner />
                  )}
                </Button>
                <Button
                  onClick={() => {
                    reset();
                    clearSignUpStatus();
                  }}
                >
                  {txt.reset_form}
                </Button>
              </Grid>
            </form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
