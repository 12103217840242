import { Box, Center, Flex, GridItem, Icon, SimpleGrid } from "@chakra-ui/react";
import { useLayoutEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { AiOutlineMenu } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useBoundingRect } from "../../../../../../utils/utilsReact/useBoundingRect";
import { configOneLargeSound } from "../../../SheetCreator/taskSheets/OneLargeSound";
import { TaskSheet } from "../../../SheetCreator/taskSheets/TaskSheet";
import { AbcBlock, Font } from "../../shared/icons";
import { DrawerFont, DrawerGuidelines, DrawerSound } from "./setting-modals";
import { NavButton } from "./shared";

/* This should be placed under booklet-creator? */

export const SheetEditor = () => {
  const [ref, br] = useBoundingRect();
  const navigate = useNavigate();

  const [activeSetting, setActiveSetting] = useState<"guidelines" | "sound" | "font" | null>(null);

  /* Hack to render sheet preview correctly... */
  const [foo, setFoo] = useState(1);
  useLayoutEffect(() => {
    setFoo(0);
  }, []);

  return (
    <Div100vh>
      <DrawerSound
        activeSound="m"
        isOpen={activeSetting === "sound"}
        onClose={() => setActiveSetting(null)}
        onSetSound={() => {}}
        sounds={"abcdefghijklmnopqrstuvwxyz".split("")}
      />
      <DrawerGuidelines
        isOpen={activeSetting === "guidelines"}
        onClose={() => setActiveSetting(null)}
        sounds={[]}
      />
      <DrawerFont
        isOpen={activeSetting === "font"}
        onClose={() => setActiveSetting(null)}
        sounds={[]}
      />
      <Flex flexDir="column" h="100%">
        <Center bg="green.100" w="100%" h="100%" ref={ref}>
          <Box h={br.width * 1.35 + foo} w={br.width} pos="relative">
            <svg width="100%" height="100%">
              {foo === 0 && <TaskSheet sheetProps={configOneLargeSound.fnMkSheet()} />}
            </svg>
          </Box>
        </Center>
        {/* What is the maximum amount of settings a sheet can have? */}
        {/* Currently, 5 (sound poster). */}
        {/* This "bottom bar" will be a bit special? justifyContent: flex-end? */}
        <SimpleGrid bg="blue.200" columns={5} h="3.8em" mt="auto" py=".2em" w="100%">
          <NavButton
            icon={<Icon fontSize="1.4em" as={FaSave} />}
            label="Save"
            onClick={() => navigate("../booklet-editor")}
          />
          <GridItem />
          <NavButton
            icon={<Icon fontSize="1.5em" as={AbcBlock} />}
            label="Sound"
            onClick={() => setActiveSetting("sound")}
          />
          <NavButton
            icon={<Icon fontSize="1.8em" as={Font} />}
            label="Font"
            onClick={() => setActiveSetting("font")}
          />
          <NavButton
            icon={<Icon fontSize="1.4em" as={AiOutlineMenu} />}
            label="Guidelines"
            onClick={() => setActiveSetting("guidelines")}
          />
        </SimpleGrid>
      </Flex>
      {/* These .... */}
      {/* Bottom icons should not be part of the editor? */}
      {/* Yeah... feels like best would be to not show this inside sheet editor. */}
    </Div100vh>
  );
};
