import { Button, Divider, Flex, Grid, Heading, Icon, Spinner, Stack, Text } from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useTxt } from "@imaldev/imal-react-ui/i18n";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Stripe, { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { ImCircleLeft } from "react-icons/im";
import { MdPayment } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { callCloudFn, wGetDocData } from "../../../../../../api/shared";
import { useAuthentication } from "../../../../../../auth/auth";
import { MainBottomNav } from "../sheet-editor/shared";

export const Profile = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthentication();

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Flex flexDir="column" p="1em" overflowY="auto">
          <Heading size="md">Personal Information</Heading>
          <Grid templateColumns="4fr 6fr" rowGap=".8em">
            <Text>Name</Text>
            <Text>foobar</Text>
            <Text>Email</Text>
            <Text>foobar@imalabc.de</Text>
          </Grid>

          <Divider my="1.5em" />

          <Flex flexDir="column" h="100%">
            <Heading size="md">Application Settings</Heading>
            <Flex>
              <Text>Letter sequence</Text>
            </Flex>
            <Flex>
              <Text>Typeface</Text>
            </Flex>
          </Flex>

          <Divider my="1.5em" />

          {/* Some logic for whether payment stuff is enabled for a locale. */}
          {/* Either feature flag or look at db to see whether this locale has functioning payments. */}
          {/* Two options: */}
          {/* 1. have active subscription: user can edit (switch between yearly and monthly) and cancel. */}
          {/* 2. don't have subscription: user can purchase a new subscription. */}
          <Heading mt=".4em" size="md">
            Subscription
          </Heading>
          <Grid templateColumns="4fr 6fr" alignItems="center" rowGap=".8em">
            <Text>No active subscription</Text>
            <Button
              leftIcon={<Icon as={MdPayment} />}
              mx="auto"
              onClick={() => navigate("purchase-subscription")}
              w="fit-content"
            >
              Purchase
            </Button>
            {/* TODO: add purchase history? */}
            {/*       if no history, show this. If history, show first entry, */}
            {/*       and let user see more entries by expanding a collapsible (expand in steps?). */}
          </Grid>

          <Divider my="2em" />
          <Stack spacing="1em">
            <Heading m="0" mb=".2em" size="md">
              Invite a friend
            </Heading>
            <Grid templateColumns="4fr 6fr" gap="1.5em" alignItems="center" rowGap=".8em">
              <Button disabled>Super-invite</Button>
              <Text>3 remaining</Text>
              <Button disabled>Normal invite</Button>
            </Grid>
          </Stack>
          <Divider my="2em" />
          {/* TODO: fix logout not working? -> sent to screen with spinner which never ends. */}
          <Button
            colorScheme="red"
            h="3em"
            onClick={() => {
              signOut();
              navigate("/login");
            }}
            py=".7em"
            variant="outline"
          >
            Log out
          </Button>
        </Flex>
        <MainBottomNav />
      </Flex>
    </Div100vh>
  );
};

type PaymentInterval = "monthly" | "yearly";

const stripeClientSecret = process.env.REACT_APP_IS_PRODUCTION
  ? "pk_live_TuAyx7goDxY4R6WQXVoXJd1j"
  : "pk_test_ZW9VxWaJ7Jki2P1gnxrMEjTA";

const stripePromise = loadStripe(stripeClientSecret);

export const PurchaseSubscription = () => {
  const navigate = useNavigate();
  const [paymentStep, setPaymentStep] = useState<"payment_interval" | "card_details">(
    "payment_interval"
  );
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState<
    Stripe.PaymentIntent["client_secret"] | null
  >();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [isLoading, setIsLoading] = useState(false); // ???
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(null);

  useEffect(() => {
    const execGetPlans = async () => {
      setPlans(await getPlans("app_sheets_abc", "de_DE"));
    };
    execGetPlans();
  }, []);

  useEffect(() => {
    const execSetupSubscription = async () => {
      const response = (await callCloudFn("setupSubscriptionPayment", {
        priceId: selectedPlan!.priceId
      })) as any;

      const secret = response.data.clientSecret as Stripe.PaymentIntent["client_secret"];
      console.log("secret:");
      console.log(secret);
      setPaymentIntentClientSecret(secret);
      /* TODO: create adapter for this cloud function. */
      /* With adapter pattern, will be simpler to change from cloud function to something else in the future. */
    };

    if (selectedPlan) execSetupSubscription();
  }, [selectedPlan]);

  return (
    <Div100vh>
      <Flex flexDir="column" h="100%">
        <Flex alignItems="center" bg="gray.400" gridGap="2em" p="2em 1em" boxSizing="content-box">
          <Icon as={ImCircleLeft} fontSize="2em" onClick={() => navigate("..")} />
          <Heading m="0" size="lg">
            Purchase subscription
          </Heading>
        </Flex>
        {paymentStep === "payment_interval" && (
          <>
            <Stack p="1em">
              {plans.map((p, i) => (
                <SubscriptionPlan
                  {...p}
                  key={i}
                  onClick={async () => {
                    setSelectedPlan(p);
                    setPaymentStep("card_details");
                  }}
                />
              ))}
            </Stack>
          </>
        )}
        {paymentStep === "card_details" &&
          (!paymentIntentClientSecret ? (
            <Spinner />
          ) : (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntentClientSecret
              }}
            >
              <CheckoutForm />
            </Elements>
          ))}
      </Flex>
    </Div100vh>
  );
};

/* TODO?: look for library which has these kinds of type definitions. */
const currencySymbolByCode = {
  eur: "€",
  usd: "$",
  nok: "NOK"
};
type CurrencyCode = keyof typeof currencySymbolByCode;
const getCurrencySymbol = (currencyCode: CurrencyCode) =>
  currencySymbolByCode[currencyCode] ?? "??";

type SubscriptionPlan = {
  amount: number;
  currency: CurrencyCode;
  interval: PaymentInterval; // Stripe.Recurring.Interval (?)
  priceId: string; // Stripe.Price.id
};

const getPlans = async (appName: string, locale: string): Promise<SubscriptionPlan[]> => {
  const data = (await wGetDocData(`/${appName}/${locale}`)) as any;
  return Object.values(data.plans);
};

/* props for this can be PaymentPlan and an onClick?   */
/* const SubscriptionPlan = (props: { label: string; onClick: () => void; price: number }) => { */
const SubscriptionPlan = (props: SubscriptionPlan & { onClick: () => void }) => {
  const { txt } = useTxt({
    [ELocale.de_DE]: {
      month: "Monatlich",
      year: "Jahrlich"
    },
    [ELocale.en_US]: {
      month: "Monthly",
      year: "Yearly"
    }
  });

  return (
    <Stack
      alignItems="center"
      bg="white"
      border="3px solid"
      borderColor={"gray.400"}
      borderRadius=".35em"
      onClick={props.onClick}
      p=".85em"
    >
      <Text fontWeight="bold">{txt[props.interval as keyof typeof txt]}</Text>
      <Text>{`${getCurrencySymbol(props.currency)} ${props.amount / 100},-`}</Text>
    </Stack>
  );
};

const CheckoutForm = () => {
  const elements = useElements();
  const stripe = useStripe();

  if (!stripe || !elements) return <Spinner />;

  /* Possible to complete payment without `return_url`?. */

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        console.log("submit!");

        // Stripe.js has not yet loaded; disabling rendering of form until Stripe.js has loaded.

        const result = await stripe.confirmPayment({
          elements, // `elements` instance that was used to create the Payment Element
          confirmParams: {
            /* Where to return to after payment? */
            /* Show some kind of success/failure view? */
            /* And then go to main page off app? Or go to same place we were in settings? */
            receipt_email: "ludwignagelhus@gmail.com",
            return_url:
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000/app/payment-success"
                : "https://dev.imalabc.de/signup" // how to set correct url?
            /* The url is passed a url parameter, containing some things about the payment intent(?). */
          }
        });

        if (result.error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          console.log("error...");
          console.log(result.error.message);
          return {};
        } else {
          console.log("success!");
          return result;
        }
      }}
    >
      <PaymentElement />
      <Flex gridGap="1.5em" mt=".8em">
        <Button type="submit">Next</Button>
      </Flex>
    </form>
  );
};
