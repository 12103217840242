import {
  Box,
  Button,
  Center,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon as ChakraIcon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text
} from "@chakra-ui/react";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { useLocale, useTxt } from "@imaldev/imal-react-ui/i18n";
import Color from "color";
import firestore, { deleteDoc, query } from "firebase/firestore";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FaEye, FaTrash } from "react-icons/fa";
import { TiPencil } from "react-icons/ti";
import { wGetCollection, wGetDoc } from "../../../../api/shared";
import { useUser } from "../../../../api/user/context";
import { FlexColumn, FlexRow } from "../../../../components/layout/Flex/Flex";
import { dbSaveSequence, SequenceEditor } from "../../../../components/SequenceEditor";
import { useGlobalTxt } from "../../../../i18n/useGlobalTxt";
import {
  SoundSequence,
  useBookSequences
} from "../../../../utils/utilsiMAL/soundSequences/sequences";
import { useHover } from "../../../../utils/utilsReact/useHover";
import { TUserSequence } from "../AppSheetsAbc";
import { useDefaultSequence } from "../context/AppSheetsAbcUserData/contextUserDataAppSheetsAbc";
import { useSheets } from "../SheetCreator/contexts/ContextSheets";
import { Activatable } from "../SheetCreator/taskSheets/shared/components/settings/SettingButton/SettingButton";
import { Book } from "./Book";

const dbDeleteUserSequence = async (arg: {
  locale: ELocale;
  sequenceId: string;
  userId: string;
}) => {
  deleteDoc(
    wGetDoc(`/app_sheets_abc/${arg.locale}/users/${arg.userId}/sequences/${arg.sequenceId}`)
  );
};

/* TODO: move to new place where this belongs. */
const translations = {
  [ELocale.de_DE]: {
    changing_your_sequence_will_delete_all_sheets: (seqName: string) =>
      `Beim Wechsel des Schulbuches werden alle Arbeitsblätter gelöscht. Zur Reihenfolge ${seqName} wechseln?`,
    warning: "Achtung!"
  },
  [ELocale.en_US]: {
    changing_your_sequence_will_delete_all_sheets: () =>
      "Changing sounds will delete all sheets in your current session. Continue?",
    warning: "Warning!"
  }
};

const useUserSequences = (userId: string) => {
  const { locale } = useLocale();
  /* TODO: replace with new home made wrapper. */
  /* Where is it? wut called? not sure we have wrapper for using collection, only individual docs. */
  const [sequences, isLoading, error] = useCollectionData(
    query(
      wGetCollection(`/app_sheets_abc/${locale}/users/${userId}/sequences`)
    ) as firestore.Query<TUserSequence>
  );

  return {
    isLoading,
    userSequences: sequences ?? []
  } as { isLoading: boolean; userSequences: TUserSequence[] };
};

const SeqCardIcon = chakra(ChakraIcon, {
  baseStyle: {
    h: "100%",
    transition: "ease .1s",
    w: "100%",
    _hover: {
      color: "#555",
      cursor: "pointer"
    }
  }
});

/* TODO: put in a nice place. factory/i18n? */
type WithLocale = { locale: ELocale };

type PropsUserSequence = Activatable &
  WithLocale & {
    onSetAsDefault?: () => void;
    sequence: TUserSequence;
    userId: string;
  };

const UserSequence = ({
  active = false,
  locale,
  onSetAsDefault = () => {},
  sequence,
  userId
}: PropsUserSequence) => {
  const { hoverHandlers, hasHover: showingSounds } = useHover();
  const [isShowingDeletionWarning, setIsShowingDeletionWarning] = useState(false);
  const [isEditingSequence, setIsEditingSequence] = useState(false);
  const closeEditor = () => setIsEditingSequence(false);

  const closeDeletionWarning = () => setIsShowingDeletionWarning(false);
  const showDeletionWarning = () => setIsShowingDeletionWarning(true);

  const deleteSequence = async () => {
    closeDeletionWarning();
    dbDeleteUserSequence({ locale, sequenceId: sequence.id, userId });
  };

  return (
    <Flex
      alignItems="center"
      bg="#b0cad1"
      border={`4px solid ${active ? "#43A52B" : new Color("#b0cad1").darken(0.2).hex()}`}
      borderRadius=".3rem"
      boxShadow="lg"
      boxSizing="border-box"
      cursor="pointer"
      h="fit-content"
      justifyContent="space-between"
      onClick={onSetAsDefault}
      m="1rem"
      p=".5rem"
      transition="ease .1s"
      w="22.5rem"
      _hover={{
        filter: "brightness(96%)"
      }}
    >
      <SequenceEditor
        onClose={closeEditor}
        isOpen={isEditingSequence}
        onSave={(seq: SoundSequence) => {
          dbSaveSequence(locale, userId, seq);
          closeEditor();
        }}
        sequence={sequence}
        title="Reihenfolge bearbeiten"
      />
      <Text color="#252525" fontSize="1.4rem" ml="1rem" my=".7rem" w="100%">
        {sequence.name}
      </Text>
      {/* put icons+popovers inside a container with a liiittle top margin. (why??) */}
      <Popover isOpen={showingSounds}>
        <PopoverTrigger>
          <Box {...hoverHandlers} h="inherit" mr="1rem" w="2rem">
            <SeqCardIcon
              as={FaEye}
              h="100%"
              onClick={(e: any) => e.stopPropagation()}
              pt=".12rem"
              w="inherit"
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent w="35rem">
          <Flex flexWrap="wrap" m=".5rem">
            {sequence.sounds.map((s, i) => (
              <Text
                as="b"
                bg="beige"
                borderRadius=".14rem"
                boxShadow="md"
                fontSize="1.15rem"
                h="fit-content"
                key={i}
                m=".3rem"
                p=".3rem .4rem"
                textAlign="center"
                w="2rem"
              >
                {s}
              </Text>
            ))}
          </Flex>
        </PopoverContent>
      </Popover>
      <Popover isLazy isOpen={false} onClose={closeDeletionWarning}>
        <PopoverTrigger>
          <Box>
            <SeqCardIcon
              as={TiPencil}
              alignSelf="center"
              h="100%"
              mr="1rem"
              onClick={(e: any) => {
                e.stopPropagation();
                setIsEditingSequence(true);
              }}
              w="2rem"
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent p="1rem" w="35rem">
          <PopoverCloseButton _hover={{ bg: "lightgray", cursor: "pointer" }} />
        </PopoverContent>
      </Popover>
      {/* Popover onHover that cant delete activeSeq. */}
      <Popover isOpen={isShowingDeletionWarning} onClose={closeDeletionWarning}>
        <PopoverTrigger>
          {/* Wrapper needed for icon to work with trigger. */}
          <Box>
            <SeqCardIcon
              _hover={{
                color: active ? "default" : "#999",
                cursor: active ? "default" : "pointer"
              }}
              color={active ? "#999" : "default"}
              as={FaTrash}
              alignSelf="center"
              mr=".5rem"
              onClick={(e: any) => {
                e.stopPropagation();
                if (!active) showDeletionWarning();
              }}
              w="1.3rem"
            />
          </Box>
        </PopoverTrigger>
        {/* When don't close onLeave, positioning unexpectedly changes/jumps. */}
        <PopoverContent onMouseLeave={closeDeletionWarning} w="35rem">
          <PopoverHeader>
            <Text as="b" fontSize="1.6rem">
              Bestätigen Sie die Löschung der Reihenfolge "{sequence.name}".
            </Text>
          </PopoverHeader>
          <Flex mx="auto" mt=".7rem" mb="1.5rem">
            <Button
              bg="#60B64B"
              disabled={active}
              mx="1rem"
              onClick={(e) => {
                e.stopPropagation();
                deleteSequence();
              }}
            >
              Bestätigen
            </Button>
            <Button
              bg="#F06C5B"
              mx="1rem"
              onClick={(e) => {
                e.stopPropagation();
                closeDeletionWarning();
              }}
            >
              Abbrechen
            </Button>
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

/* Needs to be dynamic... */
export const SequenceMenu = () => {
  const { defaultSequence, setDefaultSequence } = useDefaultSequence();
  const { locale } = useLocale();
  const { sequences } = useBookSequences();
  const { user } = useUser();
  const [isCreatingSequence, setIsCreatingSequence] = useState(false);
  const { userSequences, isLoading } = useUserSequences(user.id);
  const { deleteAllSheets, sheets } = useSheets();
  const [isShowingSheetWarning, setIsShowingSheetWarning] = useState(false);
  const { txt } = useTxt(translations);
  const { txt_g } = useGlobalTxt();
  const [idNextDefaultSeq, setIdNextDefaultSeq] = useState<null | string>(null);

  const closeWarning = () => setIsShowingSheetWarning(false);

  const getSeqName = (idSeq: string) => {
    return (
      ([sequences, userSequences] as SoundSequence[][]).flat().find((seq) => seq.id === idSeq)
        ?.displayName ?? "foobar"
    );
  };

  /* TODO: lexically order seqs by name. */

  return (
    <Box overflowY="scroll" pos="relative">
      <Flex justifyContent="center" m="auto" mt="1.5rem" w="80rem" maxW="100%">
        <SequenceEditor
          onClose={() => setIsCreatingSequence(false)}
          isOpen={isCreatingSequence}
          onSave={(seq) => {
            dbSaveSequence(locale, user.id, seq);
            setIsCreatingSequence(false);
          }}
        />
        <FlexColumn>
          <Flex mb=".6rem">
            <Text as="b" color="#222" fontSize="2.3rem" ml="2.3rem">
              Ihre Reihenfolgen
            </Text>
            <Button onClick={() => setIsCreatingSequence(true)} ml="6rem">
              Neue Reihenfolge
            </Button>
          </Flex>
          <Flex minH="10rem" wrap="wrap" w="100%" zIndex="100">
            {isLoading ? (
              <Center h="100%" w="100%">
                <Spinner />
              </Center>
            ) : userSequences.length === 0 ? (
              <Center w="100%" h="100%">
                <Text lineHeight="2.1rem" mt="2.5rem" textAlign="center">
                  Noch keine selbsterstellte Reihenfolgen.
                </Text>
              </Center>
            ) : (
              userSequences.map((seq, i) => {
                return (
                  <UserSequence
                    active={seq.id === defaultSequence?.id}
                    sequence={seq}
                    locale={locale}
                    onSetAsDefault={() => {
                      if (sheets.length > 0) {
                        setIdNextDefaultSeq(seq.id);
                        setIsShowingSheetWarning(true);
                      } else setDefaultSequence(seq.id);
                    }}
                    userId={user.id}
                    key={i}
                  />
                );
              })
            )}
          </Flex>
          <Divider borderTop="2px solid #555" my="2rem" />
          <Flex flexWrap="wrap">
            {sequences.map((seq, i) => (
              <Box key={i} m="1rem">
                <Book
                  active={seq.name === defaultSequence?.name}
                  onClick={() => {
                    if (sheets.length > 0) {
                      setIdNextDefaultSeq(seq.id);
                      setIsShowingSheetWarning(true);
                    } else setDefaultSequence(seq.id);
                  }}
                  sequence={seq}
                />
              </Box>
            ))}
          </Flex>
        </FlexColumn>
        <Modal onClose={() => setIsShowingSheetWarning(false)} isOpen={isShowingSheetWarning}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading>{txt.warning}</Heading>
            </ModalHeader>
            <ModalBody>
              <Text>
                {txt.changing_your_sequence_will_delete_all_sheets(
                  (idNextDefaultSeq && getSeqName(idNextDefaultSeq)) ?? "foo"
                )}
              </Text>
              <FlexRow justifyContent="space-around" m="1rem" mt="1.5rem">
                <Button
                  onClick={() => {
                    closeWarning();
                    idNextDefaultSeq && setDefaultSequence(idNextDefaultSeq);
                    deleteAllSheets();
                  }}
                >
                  {txt_g.yes}
                </Button>
                <Button onClick={closeWarning}>{txt_g.cancel}</Button>
              </FlexRow>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};
