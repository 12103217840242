import { Center, chakra, Flex, Icon, Image, Tooltip } from "@chakra-ui/react";
import * as R from "ramda";
import { ReactNode, useMemo } from "react";
import { FaCog, FaUsers } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../../../api/user/context";
import { isAdmin } from "../../../../api/user/user";
import { useAuthentication } from "../../../../auth/auth";
import { cs } from "../../../../styles/colors";
import iMALLogo from "../../../../utils/utilsiMAL/images/logo/logo_german_white_text.png";
import { useSheets } from "../SheetCreator/contexts/ContextSheets";
import germanBooks from "./germanBooksAbc.png";
import stackedSheets from "./stacked_sheets_small.png";

const BarIcon = chakra(Icon, {
  baseStyle: {
    color: "white",
    cursor: "pointer",
    fontSize: "3.5vmin",
    pt: ".1rem"
  }
});

/* Need to use rest to apply styles from chakra? */
const BarButton = chakra(
  ({
    children = undefined as ReactNode | undefined,
    onClick = () => {},
    to = "",
    tooltip = ""
  }) => {
    const { pathname } = useLocation();

    /* This may break if one route is a substring of another. */
    /* If so, split pathname by forward-slash, and compaere on second level path item. */
    const isActive = useMemo(() => to && pathname.includes(to), [to, pathname]);

    return (
      <Tooltip fontSize="md" label={tooltip}>
        <Center
          as={to ? Link : undefined}
          to={to ?? ""}
          bg={isActive ? cs.TURQUOISE : "default"}
          borderX=".05vmin solid #80808030"
          cursor="pointer"
          h="inherit"
          onClick={onClick}
          transition="background 0.14s ease"
          w="12vmin"
          _hover={{
            bg: isActive ? cs.TURQUOISE : cs.NAVY_LIGHT
          }}
        >
          {children}
        </Center>
      </Tooltip>
    );
  }
);

/* TODO: When refactoring the menubar... perhaps have a render prop for rendering the navigation section? */
/* This is essentially what is MenuBar2. */

export const HEIGHT_MENU_BAR = "8vmin";

type Props = {
  buttons?: ReactNode;
};

/* TODO: show "back-arrow" when edit a sheet? */
export const MenuBar = ({ buttons }: Props) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const { user } = useUser();
  const { setIdActiveSheet } = useSheets();
  const navigate = useNavigate();

  const isLogoClickable = useMemo(() => {
    const pathLevels = pathname.split("/");
    return pathLevels.includes("app") && R.last(pathLevels) !== "app";
  }, [pathname]);

  return (
    <Flex
      bg={cs.NAVY}
      boxShadow="0px 2px 5px #444"
      justifyContent="space-between"
      alignItems="flex-end"
      w="inherit"
      h="8vmin"
    >
      <Image
        cursor={isLogoClickable ? "pointer" : "default"}
        h="70%"
        ml="1vh"
        onClick={
          isLogoClickable
            ? () => {
                setIdActiveSheet(null);
                navigate("sheetcreator");
              }
            : undefined
        }
        p="1.2vh"
        src={iMALLogo}
      />
      <Flex h="100%">
        {R.not(["app", "login", "signup"].includes(R.last(pathname.split("/")) ?? "")) && (
          <>
            <BarButton
              onClick={() => setIdActiveSheet(null)}
              to="sheetcreator"
              tooltip="Arbeitsblätter"
            >
              <Image src={stackedSheets} maxW="70%" maxH="70%" />
            </BarButton>
            <BarButton to="sequences" tooltip="Lautreihenfolge">
              <Image maxW="70%" maxH="70%" src={germanBooks} />
            </BarButton>
            {isAdmin(user) && (
              <BarButton to="admin_dashboard" tooltip="Nutzeradmin">
                <BarIcon as={FaUsers} fontSize="4.5vmin" />
              </BarButton>
            )}
            <BarButton to="settings" tooltip="Einstellungen">
              <BarIcon as={FaCog} />
            </BarButton>
          </>
        )}
        <BarButton
          onClick={() => {
            signOut();
            navigate("/login");
          }}
          tooltip="Abmelden"
        >
          <BarIcon as={ImExit} />
        </BarButton>
      </Flex>
    </Flex>
  );
};
