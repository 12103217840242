import { TaskFontLoader } from "@imaldev/imal-react-ui/abc";
import Div100vh from "react-div-100vh";
import { Navigate, Route, Routes } from "react-router-dom";
import { IS_FAST_DEV } from "./constants";
import { ContextsWrapper } from "./ContextsWrapper";
import { HomeRoutes } from "./views/homeRoutes/homeRoutes";
import { LogIn } from "./views/LogIn/LogIn";
import { AppSheetsAbc } from "./views/PostLogin/AppSheetsAbc/AppSheetsAbc";
import { PostLogin } from "./views/PostLogin/PostLogin";
import { SignUp } from "./views/SignUp/SignUp";

/* TOOD: fix sizing for app container (don't use vh). */

export const App = () => {
  return (
    <ContextsWrapper>
      <Div100vh>
        <TaskFontLoader />
        {IS_FAST_DEV ? (
          <AppSheetsAbc />
        ) : (
          <Routes>
            <Route path="app/*" element={<PostLogin />} />
            <Route path="home" element={<HomeRoutes />} />
            <Route path="login" element={<LogIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="/" element={<Navigate to="login" />} />
          </Routes>
        )}
      </Div100vh>
    </ContextsWrapper>
  );
};
