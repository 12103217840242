import {
  applyCap,
  ECap,
  getSounds,
  getSoundsInWord,
  TFilter,
  TSound
} from "@imaldev/imal-factory/abc";
import { ELocale } from "@imaldev/imal-factory/i18n";
import { Percent, takeRandom } from "@imaldev/imal-factory/ts";
import { useLocale } from "@imaldev/imal-react-ui/i18n";
import { produce } from "immer";
import { append, dissoc, head, includes, intersection, mergeRight, not, pipe, propEq } from "ramda";
import { useEffect, useMemo } from "react";
import {
  ETaskFontVariant,
  getDefaultTypefaceName
} from "../../../../../../utils/utilsiMAL/fonts/shared";
import { getTaskFont } from "../../../../../../utils/utilsiMAL/fonts/typefaces";
import { useDefaultSequence } from "../../../context/AppSheetsAbcUserData/contextUserDataAppSheetsAbc";
import { useImageByWord } from "../../../context/WordImages";
import { useSheets } from "../../contexts/ContextSheets";
import { useWords } from "../../contexts/WordsContext";
import { TMenuSheetsFor } from "../../SheetMenu/SheetMenu";
import { EditorContainer } from "../shared/components/settings/editor/EditorContainer";
import { SelectFont } from "../shared/components/settings/SelectFont/SelectFont";
import { SelectSounds } from "../shared/components/settings/SelectSounds/SelectSounds";
import { SelectWordWithImage } from "../shared/components/settings/SelectWordWithImage/SelectWordWithImage";
import {
  BlankSheet,
  PAGE_A4_HEIGHT,
  PAGE_A4_WIDTH
} from "../shared/components/sheets/BlankSheet/BlankSheet";
import { WriteWord } from "../shared/components/sheets/WriteWord/WriteWord";
import {
  ESheet,
  mkAbstractSheet,
  MkSheet,
  SheetPreview,
  TNewSheetType,
  TSheetConfig
} from "../shared/misc";
import { setCap, TWithCap } from "../shared/sheetTypes/withCap";
import { setFocusedSoundCurried, TWithFocusedSound } from "../shared/sheetTypes/withFocusedSound";
import { setFont, TWithFont } from "../shared/sheetTypes/withFont";
import { enableSoundsToFocused, toggleSound, TWithSounds } from "../shared/sheetTypes/withSounds";
import {
  mkWriteWord,
  setWriteWord,
  TWithWriteWords,
  TWordObj
} from "../shared/sheetTypes/withWords";

export type TOneLargeWWII = TNewSheetType<ESheet.ONE_LARGE_WWII> &
  TWithCap &
  TWithFocusedSound &
  TWithFont &
  TWithSounds &
  TWithWriteWords<1>;

/* BUG: Once set initial image word to "Mops" for de_DE, but doesn't seem like we have an image for this? */
/* Has the image file been renamed? */
const wordFilterCreators: FilterCreator[] = [
  (arg) => mergeRight(arg, { withImage: true }),
  ({ mainSound }) => ({ mainSound, focusedPosition: "first", withImage: true })
];

/* TODO: put this some shared thingy. */
export type FilterCreator = (arg: { mainSound?: TSound; sounds?: TSound[] }) => TFilter;

export const onlyHasKnownSounds = (knownSounds: TSound[], locale: ELocale) => (wObj: TWordObj) => {
  const wordSounds = getSoundsInWord(locale, wObj.value, false);
  return wordSounds.length === intersection(wordSounds, knownSounds).length;
};

export const EditorOneLargeWWII = () => {
  const { activeSheet: s } = useSheets() as { activeSheet: TOneLargeWWII };
  const { updateSheet } = useSheets();
  const { defaultSequence } = useDefaultSequence();
  const { locale } = useLocale();
  /* Extract this out into a function?? */
  /* the useTaskWords2 hook can take filters as arg... */
  /* returns a fn which accepts a sheet. uses focusedSound and sounds of the sheet to find words. */
  /* This can be used for many sheets. */
  const { words, getWords } = useWords(
    { mainSound: s.focusedSound, sounds: s.sounds, withImage: true },
    { mainSound: s.focusedSound, mainSoundPos: "first", withImage: true }
  );

  useEffect(() => {
    if (s.words.every(propEq("value", ""))) updateSheet(setWriteWord(0)(words[0])(s) as typeof s);
  }, []);

  /* There is something funky going on when sheet is created... */
  useEffect(() => {
    console.log(JSON.stringify(dissoc("image", s), null, 2));
  }, [JSON.stringify(s)]);

  return (
    <EditorContainer
      sheet={s}
      settings={
        <>
          <SelectSounds
            cap={s.cap}
            disableCapFirstUpper
            focusedSound={s.focusedSound}
            setCap={pipe(setCap(s), updateSheet)}
            selectedSounds={s.sounds}
            onSelectSound={pipe(
              setFocusedSoundCurried(s),
              enableSoundsToFocused(defaultSequence) as () => typeof s,
              (s) => {
                /* Try to merge this logic with the filters passed to `useWords`. */
                /* This is too verbose and non-DRY. */
                const soundsInWord = getSoundsInWord(locale, s.words[0].value ?? "FoObar", false);
                const hasOnlyKnownSounds =
                  intersection(soundsInWord, append(s.focusedSound, s.sounds)).length ===
                  soundsInWord.length;
                if (!(hasOnlyKnownSounds && soundsInWord.includes(s.focusedSound))) {
                  let availableWords = getWords({
                    mainSound: s.focusedSound,
                    sounds: s.sounds,
                    withImage: true
                  });
                  if (availableWords.length === 0)
                    availableWords = getWords({
                      mainSound: s.focusedSound,
                      mainSoundPos: "first",
                      withImage: true
                    });
                  const ns = produce(s, (s) => {
                    s.words[0].value = takeRandom(availableWords);
                  });
                  return ns;
                } else return s;
              },
              updateSheet
            )}
            toggleSound={pipe(toggleSound(s) as any, updateSheet)}
          />
          <SelectWordWithImage
            activeWord={s.words[0].value}
            setWord={(w) => updateSheet(setWriteWord(0)(w)(s) as typeof s)}
            words={words}
          />
          <SelectFont
            activeFontVariant={s.font.variant}
            activeTypeface={s.font.typeface}
            setFont={pipe(setFont(s), updateSheet)}
          />
        </>
      }
    />
  );
};

/* mkSheet needs focused Sound and sounds..? */
export const mkOneLargeWWII: MkSheet<TOneLargeWWII> = (arg) => {
  const focusedSound = arg?.pSheet?.focusedSound ?? "",
    sounds = arg?.pSheet?.sounds ?? [];
  const sheet: TOneLargeWWII = {
    ...mkAbstractSheet(),
    cap: ECap.NORMAL,
    focusedSound: arg?.pSheet?.focusedSound ?? "",
    font: getTaskFont(
      arg?.typefaceName ?? getDefaultTypefaceName(arg?.locale ?? ELocale.de_DE),
      ETaskFontVariant.REGULAR
    ),
    name: ESheet.ONE_LARGE_WWII,
    sounds: getSounds(arg?.locale ?? ELocale.de_DE),
    /* Yeah, getWords should take the word-rule config(s). */
    /* This pattern will probably be used in a lot of places? */
    words: [
      mkWriteWord(
        arg?.getWords
          ? {
              value:
                head(
                  arg.getWords(
                    1,
                    wordFilterCreators.map((f) => f({ mainSound: focusedSound, sounds }))
                  )
                ) ?? ""
            }
          : {}
      )
    ],
    ...arg?.pSheet
  };

  return sheet;
};

export const menuSheetsOneLargeWWII: TMenuSheetsFor<ESheet.ONE_LARGE_WWII> = {
  [ELocale.de_DE]: mkOneLargeWWII({
    locale: ELocale.de_DE,
    pSheet: {
      words: [mkWriteWord({ value: "Schaf" })],
      sounds: getSounds(ELocale.de_DE)
    }
  }),
  [ELocale.es_ES]: mkOneLargeWWII({
    locale: ELocale.es_ES,
    pSheet: {
      words: [mkWriteWord({ value: "coche" })],
      sounds: "coche".split("")
    }
  })
};

export const Sheet: SheetPreview<ESheet.ONE_LARGE_WWII> = ({
  interactive,
  // TODO?: remove functions such as this one, and instead call useSheets for its
  // updateSheet inside sheet previews and use this fn.
  /* setFontVariantForSound = () => {}, */
  sheetProps: s
}) => {
  const { locale } = useLocale();
  const wordXByLength: Record<number, Percent> = {
    1: 39,
    2: 33,
    3: 22,
    4: 14,
    5: 6,
    6: -1,
    7: -1
  };

  const word = useMemo(() => s.words?.[0]?.value, [s.words[0].value]);

  const { updateSheet } = useSheets();

  const wordText = useMemo(() => {
    return getSoundsInWord(locale, word).some((sound) =>
      not(includes(sound, append(s.focusedSound, s.sounds)))
    )
      ? head(getSoundsInWord(locale, word, true)) ?? ""
      : word;
  }, [locale, word, s.sounds.join(""), s.focusedSound]);

  const { imageByWord } = useImageByWord();
  const imageBytes = imageByWord[word];

  return (
    <BlankSheet logoSize="normal">
      <svg height="100%" viewBox={`0 0 ${PAGE_A4_WIDTH} ${PAGE_A4_HEIGHT}`} width="100%">
        <svg width="100%" height="100%" y="-2%">
          {!imageBytes ? null : (
            <image height="80%" href={`data:image/png;base64,${imageBytes}`} width="100%" />
          )}
          <svg height="100%" width="100%" x={`${wordXByLength[wordText.length]}%`} y="22%">
            <WriteWord
              editable={interactive}
              word={applyCap(wordText, s.cap)}
              fallbackFont={s.font}
              setFontVariantForSound={(iSound, fv) =>
                updateSheet(
                  produce(s, (sheet) => {
                    const currentValue = sheet.words[0].fontVariantPerSound[iSound];
                    sheet.words[0].fontVariantPerSound[iSound] = currentValue === fv ? null : fv;
                  })
                )
              }
              fontPerSound={s.words[0].fontVariantPerSound.map((variant) =>
                variant ? getTaskFont(s.font.typeface, variant) : null
              )}
            />
          </svg>
        </svg>
      </svg>
    </BlankSheet>
  );
};

export const configOneLargeWWII: TSheetConfig<ESheet.ONE_LARGE_WWII> = {
  Editor: EditorOneLargeWWII,
  videos: {
    [ELocale.de_DE]: {
      editor: "https://vimeo.com/656572270"
    }
  },
  fnMkSheet: mkOneLargeWWII,
  menuSheets: menuSheetsOneLargeWWII,
  Sheet
};
