import { imalFirebase } from "../firebase";
import { sendPasswordResetEmail as fbSendPasswordResetEmail } from "firebase/auth";
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "../utils/utilsReact/types";

export const auth = getAuth(imalFirebase);

export const ContextAuthentication = createContext<{
  authStatus: EAuthStatus;
  sendEmailVerificationEmail: () => void;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
}>(null!);

export enum EAuthStatus {
  AUTHENTICATED = "authenticated",
  ERROR_INVALID_USERNAME_OR_PASSWORD = "error:invalid_username_or_password",
  ERROR_NOT_CONNECTED_TO_INTERNET = "error:not_connected_to_internet",
  LOADING = "loading",
  SIGNED_OUT = "signedOut"
}

/* TODO?: make sign in error a union string? Reuse Firebase's codes/strings? */

/* TODO?: a lot of this is unnecessary? might be able to do this check without need for a hook..? */

/* TODO?: Move this hook to same dir as LogIn. */

/* Still should make use of localstorage to more quickly determine if user is signed in. */

/* LocalStorage might not be the solution... */
/* What wanna do, is to create a component ProtectedRoute, which renders a spinner if we have */
/* not yet determined if we are signed in. */
/* https://stackoverflow.com/questions/58769189/react-router-protected-route-with-firebase-auth-check */
export const AuthenticationProvider = ({ children }: WithChildren) => {
  const [authStatus, setAuthStatus] = useState(
    auth.currentUser === null // seems to work?
      ? EAuthStatus.SIGNED_OUT
      : EAuthStatus.AUTHENTICATED
  );

  /* TODO(?): Can be replaced/improved with react-fire/react-firebase-hooks package? */
  /* Had issues when trying it out with doc subscriptions. Try again later. */
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setAuthStatus(!!user ? EAuthStatus.AUTHENTICATED : authStatus);
    });

    return unsub;
  }, []);

  const signIn = (email: string, password: string) => {
    setAuthStatus(EAuthStatus.LOADING);
    /* async/await doesn't work here. Why? */
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setAuthStatus(EAuthStatus.AUTHENTICATED); // wonder if can do this elsewhere?

        /* Possibly move this else where later. */
        /* Would be nice to tag sessions with... if user is unpaid, subscribed, new user, etc. */
      })
      .catch((e) => {
        console.log("Error signing in:");
        console.log(e.message);
        if (e.message.includes("network-request-failed")) {
          console.log("hi");
          setAuthStatus(EAuthStatus.ERROR_NOT_CONNECTED_TO_INTERNET);
        } else setAuthStatus(EAuthStatus.ERROR_INVALID_USERNAME_OR_PASSWORD);
      });
  };

  const signOut = () => {
    auth.signOut();
    /* Replace with useEffect? */
    /* Or does react-firebase-hooks contain a fn for this? */
    /* If refactor this context to not be necessary, can get rid of it altogether. */
    setAuthStatus(EAuthStatus.SIGNED_OUT);
  };

  /* Own hook for this? */
  /* todo: handle error "auth/too-many-requests". */
  /* Perhaps send this email on backend. Nice article: */
  /* https://medium.com/firebase-developers/generating-email-action-links-with-the-firebase-admin-sdk-4b9d5e2cf914 */
  const sendEmailVerificationEmail = () => {
    auth.currentUser &&
      sendEmailVerification(auth.currentUser, {
        handleCodeInApp: false,
        url: "https://www.dev.imalabc.de"
      });
  };

  return (
    <ContextAuthentication.Provider
      value={{
        authStatus,
        sendEmailVerificationEmail,
        signIn,
        signOut
      }}
    >
      {children}
    </ContextAuthentication.Provider>
  );
};

export const useAuthentication = () => useContext(ContextAuthentication);

export enum ESendMail {
  AWAITING_INPUT = "",
  ERROR_EMAIL_INVALID = "error_email_invalid",
  ERROR_TOO_MANY_REQUESTS = "error_too_many_requests",
  ERROR_USER_NOT_FOUND = "error_user_not_found",
  LOADING = "loading",
  SUCCESS = "success"
}

/* Maybe should have some reusable logic for sending these emails. */

export const useSendPasswordResetEmail = () => {
  const [status, setStatus] = useState(ESendMail.AWAITING_INPUT);

  const sendPasswordResetEmail = async (emailAddress: string) => {
    setStatus(ESendMail.LOADING);
    fbSendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        setStatus(ESendMail.SUCCESS);
      })
      .catch((error) => {
        console.log(error);
        const errorToStatus: Record<string, ESendMail> = {
          ["auth/user-not-found"]: ESendMail.ERROR_USER_NOT_FOUND,
          ["auth/invalid-email"]: ESendMail.ERROR_EMAIL_INVALID,
          ["auth/too-many-requests"]: ESendMail.ERROR_TOO_MANY_REQUESTS
        };
        console.log(error.code);
        setStatus(errorToStatus[error.code]);
      });
  };

  return {
    clearSendEmailStatus: () => {
      setStatus(ESendMail.AWAITING_INPUT);
    },
    sendPasswordResetEmail,
    status
  };
};
