import { Box, Center, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { clamp, dec, equals, keys, range, tail, toPairs } from "ramda";
import { ImBook } from "react-icons/im";
import { IoMdPerson } from "react-icons/io";
import { useViewportDimensions } from "../../../../../../utils/utilsReact/hooks/useViewportDimensions";
import { WithChildren } from "../../../../../../utils/utilsReact/types";

export type SignUpView = "greeting" | "personalData" | "font" | "sequence" | "selectFirstPage";

type ViewApi = {
  activeView: SignUpView;
  setActiveView: (view: SignUpView) => void;
};

/* Explicitly specify prev and next view? */
/* Feel a little wrong, but think this is the right/simplest solution. */
/* Could be a record? */
export const signUpPages: Record<SignUpView, Omit<PropsProgressItem, "variant">> = {
  greeting: {
    label: "Profile",
    icon: <Icon as={IoMdPerson} fontSize="1.4em" /> // For this page, don't show an icon.
  },
  personalData: {
    label: "Profile",
    icon: <Icon as={IoMdPerson} fontSize="1.4em" />
  },
  font: {
    label: "Font",
    icon: <Text>Aa</Text>
  }, // TODO: use the right font.
  sequence: {
    label: "Letters",
    icon: (
      <Box pos="relative" w="100%" h="100%" top="-.4em">
        <Text top=".1em" left=".1em" pos="absolute">
          a
        </Text>
        <Text left=".8em" pos="absolute">
          b
        </Text>
        <Text top=".7em" left=".1em" pos="absolute">
          c
        </Text>
        <Text top=".8em" left=".7em" pos="absolute">
          d
        </Text>
      </Box>
    )
  },
  selectFirstPage: {
    label: "First Booklet",
    icon: <Icon as={ImBook} fontSize="1.4em" transform="rotate(30deg)" />
  }
};

export const SignUpLayout = (props: { content: JSX.Element; bottomNavBar?: JSX.Element }) => {
  return (
    <>
      <Flex flexDir="column" flex={1} h="100%" overflowY="auto">
        {props.content}
      </Flex>
      {props.bottomNavBar}
    </>
  );
};

export type Maybe<T> = T | Nullable;
type Nullable = null | undefined;

export const MobileLayout = (props: WithChildren) => (
  <Box style={{ ...useViewportDimensions() }}>{props.children}</Box>
);

export const ProgressBar = ({ activeView }: Pick<ViewApi, "activeView">) => {
  const nLines = clamp(0, Number.MAX_VALUE, -2 + Object.values(signUpPages).length);

  return (
    <Box bg="pink.200" w="100%" h="6em">
      <Box mx="2em" pos="relative" h="100%">
        <SimpleGrid alignItems="center" columns={nLines} pos="absolute" w="100%" h="100%">
          {range(0, nLines).map((n) => (
            <Box
              bg={
                n < dec(keys(signUpPages).findIndex(equals(activeView))) ? "green.400" : "gray.400"
              }
              h=".4em"
              key={n}
              w="100%"
            ></Box>
          ))}
        </SimpleGrid>
        <Flex
          columns={4}
          alignItems="center"
          h="100%"
          justifyContent="space-between"
          pos="absolute"
          w="100%"
        >
          {/* Maybe render this as a SimpleGrid? */}
          {/* Right, now absolute positioning might cause some issues. Long labels might also */}
          {/* prove problematic. */}
          {tail(toPairs(signUpPages)).map(([name, pPage], i) => (
            <PageProgress
              {...pPage}
              variant={
                name === activeView
                  ? "active"
                  : keys(signUpPages).findIndex(equals(name)) <
                    keys(signUpPages).findIndex(equals(activeView))
                  ? "completed"
                  : "unfinished"
              }
              key={i}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export type PropsProgressItem = {
  icon?: JSX.Element;
  label: string;
  variant: "active" | "completed" | "unfinished";
};

const PageProgress = (props: Partial<PropsProgressItem>) => {
  const colorScheme =
    props.variant === "active" ? "orange" : props.variant === "completed" ? "green" : "gray";
  return (
    <Center flexDir="column">
      <Center
        bg={`${colorScheme}.200`}
        border="3px solid"
        borderColor={`${colorScheme}.400`}
        borderRadius="50%"
        boxSize="1.5em"
        p=".5em"
      >
        {props.icon}
      </Center>
      <Text pos="absolute" top="4.6em">
        {props.label}
      </Text>
    </Center>
  );
};
