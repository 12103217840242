import { Center, Grid, GridItem, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { last } from "ramda";
import { ReactElement, useMemo, useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { TiDocumentAdd } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerBookletSettings } from "../booklet-creator";

export const MainBottomNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pageName = useMemo(() => last(pathname.split("/")) ?? "", [pathname]);

  const [isDrawerOpen, setMkNewBooklet] = useState(false); // TODO: replace with useDisclosure

  return (
    <>
      <DrawerBookletSettings
        isOpen={isDrawerOpen}
        onClose={() => setMkNewBooklet(false)}
        onSave={() => navigate("/app/booklet-editor")}
        title="New booklet"
      />
      <SimpleGrid
        bg="gray.300"
        columnGap=".3em"
        columns={3}
        w="100%"
        h="3.2em"
        mt="auto"
        py=".2em"
        px=".3em"
        boxSizing="border-box"
      >
        <NavButton
          icon={<Icon fontSize="1.3em" as={AiOutlineHome} />}
          isActive={pageName === "home"}
          label="Home"
          onClick={() => navigate("/app/home")}
        />
        <NavButton
          icon={<Icon fontSize="1.4em" as={TiDocumentAdd} />}
          label="New booklet"
          onClick={() => setMkNewBooklet(true)}
        />
        <NavButton
          isActive={pageName === "settings"}
          icon={<Icon fontSize="1.4em" as={FiSettings} />}
          label="Settings"
          onClick={() => navigate("/app/settings")}
        />
      </SimpleGrid>
    </>
  );
};

/* If no onClick, use disabled-style? */
/* Will this link-like button ever be used for anything other than navigating between routes?. */
export const NavButton = (props: {
  isActive?: boolean;
  icon?: ReactElement;
  label?: string;
  onClick?: () => void;
}) => {
  const isInteractive = !props.isActive && Boolean(props.onClick);
  return (
    <Grid
      _hover={{
        bg: isInteractive ? "gray.100" : undefined
      }}
      alignContent="center"
      bg={isInteractive ? "gray.300" : "yellow.300"}
      borderRadius=".2em"
      h="100%"
      justifyContent="center"
      role="group"
      templateRows={props.label ? "7fr 4fr" : "1fr"}
      onClick={props.onClick}
    >
      <GridItem as={Center}>{props.icon}</GridItem>
      {props.label && (
        <GridItem>
          <Text fontSize="11">{props.label}</Text>
        </GridItem>
      )}
    </Grid>
  );
};
